import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P68-E1",
    audio: "",
    video: "",
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page55/Key/E1answerKey.png",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          " Read. Write <span style='color: rgb(0, 174, 239)'>✓</span> or <span style='color: rgb(0, 174, 239)'>✗</span> for each sentence.",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },

      Write: {
        inputStyle: { width: 60 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        initialWordStyle: {
          padding: "0 6px",
          lineHeight: "35px",
          border: "2px solid",
          borderRadius: "5px",
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          height: "35px",
          width: "35px",
        },
        selectWordStyle: {
          color: "rgb(64, 200, 241)",
        },
        limitSelect: 1,
        listWords: [
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
          "✓  ✗",
        ],
        answers: [
          "0-0",
          "1-4",
          "2-0",
          "3-0",
          "4-4",
          "5-4",
          "6-0",
          "7-0",
          "8-4",
        ],
        initialValue: ["0-0", "1-4"],
      },
      Layout: `
        <div style='display: flex; flex-direction: column; gap: 20px'>
          <div style='display: flex; gap: 20px; align-items: center; width: 25cm'>
            <img style='width: 10cm' src='img/FriendsPlus/Page55/E1/1.jpg'/>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <span><b>1. </b>Ben’s taller than Carl.</span>
              <span><b>2. </b>Carl’s taller than Adam.</span>
              <span><b>3. </b>Ben is shorter than Adam.</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 20px; margin-left: auto; padding-right: 25px; position: relative'>
              <div style='display: flex; gap: 5px'><input id=0 type='Circle'/></div>
              <div style='display: flex; gap: 5px'><input id=1 type='Circle'/></div>
              <div style='display: flex; gap: 5px'><input id=2 type='Circle'/></div>
              <span style='position: absolute; top: -35px; left: -15px; font-size: 22px; font-weight: bold'>
                True / False
              </span>
            </div>
          </div>
          <div style='display: flex; gap: 20px; align-items: center; width: 25cm'>
            <img style='width: 10cm' src='img/FriendsPlus/Page55/E1/2.jpg'/>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <span><b>4. </b>Planes are faster than cars.</span>
              <span><b>5. </b>Bikes are faster than cars.</span>
              <span><b>6. </b>Cars are slower than bikes.</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 20px; margin-left: auto; padding-right: 25px'>
              <div style='display: flex; gap: 5px'><input id=3 type='Circle'/></div>
              <div style='display: flex; gap: 5px'><input id=4 type='Circle'/></div>
              <div style='display: flex; gap: 5px'><input id=5 type='Circle'/></div>
            </div>
          </div>
          <div style='display: flex; gap: 20px; align-items: center; width: 25cm'>
            <img style='width: 10cm' src='img/FriendsPlus/Page55/E1/3.jpg'/>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <span><b>7. </b>Cheetahs are faster than elephants.</span>
              <span><b>8. </b>Cheetahs are faster than mice.</span>
              <span><b>9. </b>Elephants are smaller than mice.</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 20px; margin-left: auto; padding-right: 25px'>
              <div style='display: flex; gap: 5px; margin-bottom: 25px'><input id=6 type='Circle'/></div>
              <div style='display: flex; gap: 5px'><input id=7 type='Circle'/></div>
              <div style='display: flex; gap: 5px'><input id=8 type='Circle'/></div>
            </div>
          </div>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P55-E2",
    audio: "Audios/23-audio.mp3",
    video: "",
    component: T6,
    inputSize: 100,
    maxLength: 40,
    exerciseKey: "img/FriendsPlus/Page55/Key/E2answerKey.png",
    checkUppercase: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write T (true) or F (false). <span style='color: white'><headphone name='&nbsp 23' typeimg=sound src='Audios/23-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 80,
      },
    ],
    questions: [
      {
        title: `
              <div style='display:flex;margin-top:30px'>
                  <div style='width:25cm; display:flex; flex-wrap: wrap; gap:70px'>
                      <span style='margin-right: 60px'>
                          <b>1. </b><u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspT&nbsp&nbsp</u>
                      </span>
                      <span>
                          <b>2. </b>#
                      </span>
                      <span>
                          <b>3. </b>#
                      </span>
                      <span>
                          <b>4. </b>#
                      </span>
                      <span>
                          <b>5. </b>#
                      </span>
                      <img style='height:7cm' src=''/>
                  </div>
              </div>

        `,
        answer: ["F", "T", "F", "T"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P55-E3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E3answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Complete the sentences.",
        color: "#203c8f",
        left: 82,
      },
    ],
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#ee1d23",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      // marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["deep", "fast", "high", "<span class='strikediag'>large</span>"],
        width: "max-content",
        inline: true,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top: 20px; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>The Pacific Ocean is <u style='color:gray; text-underline-offset: 5px'>&nbsplarger than&nbsp</u> the Atlantic Ocean.</span>
          <span><b>2. </b>Cheetahs are # elephants. </span>
          <span><b>3. </b>Mount Everest is # Fansipan.</span>
          <span><b>4. </b>Ba Be lake is # Hoan Kiem lake.</span>
        </div>
        `,
        answer: ["faster than", "higher than ", "deeper than"],
      },
    ],
  },
};

export default json;
