import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P26-E1",
    audio: "Audios/11-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and circle. <span style='color: white'><headphone name='&nbsp 11' typeimg=sound src='Audios/11-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "1px solid blue",
          padding: "0 5px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          borderColor: "transparent",
        },
        selectWordStyle: {
          borderColor: "blue",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: ["(A) (B) (C)", "(A) (B) (C)", "(A) (B) (C)", "(A) (B) (C)"],
        answers: ["0-0", "2-2", "1-2", "3-2"],
        initialValue: [],
      },
      Layout: `
      <div style='font-size:22px'>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 30px'>
          <div>
            <span><b>1. </b>What does Sam want?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=0 type='Circle'/></div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
              <span>a snack</span>
              <span>dinner</span>
              <span>a drink</span>
            </div>
            </div>
          </div>
          <div>
            <span><b>3. </b>What does Ben want?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=1 type='Circle'/></div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
              <span> a chicken sandwich</span>
              <span>spring rolls</span>
              <span>a cheese sandwich</span>
            </div>
            </div>
          </div>
          <div>
            <span><b>2. </b>What doesn’t Mom have?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=2 type='Circle'/></div>
              <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                <span>noodles</span>
                <span>a cheese sandwich</span>
                <span>spring rolls</span>
              </div>
            </div>
          </div>
          <div>
            <span><b>4. </b>What do they want to drink?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=3 type='Circle'/></div>
                <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                  <span>apple juice</span>
                  <span>ice tea</span>
                  <span>melon juice</span>
                </div>
              </div>
            </div>
          </div>
          
      </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P26-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E2answerKey.png",
    // textAlign: "center",
    stylesTextInput: {
      width: "22cm",
    },
    titleQuestion: [
      {
        num: "2",
        title: "Read about Hoa's weekend. Write the answers.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 22cm; border: 1px solid rgb(220, 221, 223); font-size: 22px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 10px'>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>Hoa’s weekend</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>Every Saturday morning, I play soccer in the park with my friends.  </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>I usually go to the skatepark with my sister in the afternoon. Then </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>I sometimes help Dad make dinner. On Sundays, Mom and I often ride </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>our bikes. We always go to the movie theater on Sunday evenings. </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>We love watching movies together.</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px 0 10px; position: relative'>
              <span style='color: rgb(0, 136, 202); '>&nbsp</span>
            </div>
          </div>
          <div style='display: flex; flex-direction: column; gap: 5px; margin-top: 20px; font-size: 22px'>
            <span><b>1. </b>When does Hoa play soccer?</span>
            <span style='margin-left: 20px'><u style='color:gray; text-underline-offset: 5px'>&nbspEvery Saturday morning.&nbsp</u></span>
            <span><b>2. </b>What does she usually do on Saturday afternoons?</span>
            <span style='margin-left: 20px'>#</span>
            <span><b>3. </b>Does she always help Dad make dinner on Saturday evenings?</span>
            <span style='margin-left: 20px'>#</span>
            <span><b>4. </b>What do Hoa and her mom often do on Sundays?</span>
            <span style='margin-left: 20px'>#</span>
            <span><b>5. </b>What do they always do on Sunday evenings?</span>
            <span style='margin-left: 20px'>#</span>
          </div>
        `,
        answer: [
          "She usually goes to the skatepark with her sister|She usually goes to the skatepark with her sister on Saturday afternoons.",
          "No, she sometimes helps Dad make dinner.|No, she doesn't. She sometimes helps her Dad make dinner. ",
          "They often ride their bikes.|They often ride their bikes on Sundays.",
          "They always go to the movie theater.|They always go to the movie theater on Sunday evenings.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/answerKey.png",
    component: T6,
    maxLength: 7,
    textAlign: "center",
    inputSize: 45,
    selectStyle: {
      width: 45,
      height: 45,
      textAlign: "center",
      fontSize: 27,
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "3",
        title:
          "What food do they have? Put a check (✓) or cross (✗) in the box. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],

    // isHello: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page26/E1/1.jpg' />
        <div style='display:flex; justify-content: space-between'>
          <div style='display:flex'>
            <b>1</b>. chicken &ensp;<select id=0></select>
          </div>
          <div style='display:flex'>
            <b>2</b>. bananas &ensp;<select id=1></select>
          </div>
          <div style='display:flex'>
            <b>3</b>. rice &ensp;<select id=2></select>
          </div>
          <div style='display:flex'>
            <b>4</b>. noodles &ensp;<select id=3></select>
          </div>
        </div>
        `,
        answer: ["✗", "✓", "✓", "✗"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E4",
    audio: "",
    video: "",

    exerciseKey: "",
    component: T6,
    maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What food does your friend like?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page26/E5/1.jpg" }]],
  },
};

export default json;
