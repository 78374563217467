import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P31-E1",
    // audio: "Audios/09-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/Key/E1answerKey.png",
    // textAlign: "center",
    stylesTextInput: {
      width: 200,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Say the words with nd, nt, and mp. Circle the odd-one-out. Write.",
        color: "#2d408e",
        left: 0,
      },
    ],
    component: Circle_Write,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "camp",
          "plant",
          "tent",
          "lamp",
          "<span class='strikediag'>hand</span>",
          "pond",
        ],
        width: "max-content",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 150, paddingLeft: 5 },
        answers: ["pond", "lamp", "tent"],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "16px 58px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
          fontSize: 30,
          color: "transparent",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-1", "1-2", "2-0"],
        initialValue: [],
      },
      Layout: `
        <hintbox id=0></hintbox>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><b>1. </b><img src='img/FriendsPlus/Page37/E1/1.jpg' style='width: 13cm'/> <u style='color:gray; text-underline-offset: 5px'>&nbsphand&nbsp</u></div>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><div style='position: absolute; top: 2px; left: 34px; display: flex; gap: 25px'><input id=0 type='Circle'/></div><b>2. </b><img src='img/FriendsPlus/Page37/E1/2.jpg' style='width: 13cm'/> #</div>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><div style='position: absolute; top: 2px; left: 34px; display: flex; gap: 25px'><input id=1 type='Circle'/></div><b>3. </b><img src='img/FriendsPlus/Page37/E1/3.jpg' style='width: 13cm'/> #</div>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><div style='position: absolute; top: 2px; left: 34px; display: flex; gap: 25px'><input id=2 type='Circle'/></div><b>4. </b><img src='img/FriendsPlus/Page37/E1/4.jpg' style='width: 13cm'/> #</div>
        
      `,
    },
  },

  2: {
    id: "WB5-2024-U5-P37-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/Key/E2answerKey.png",
    stylesTextInput: {
      width: 50,
      paddingLeft: 0,
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "<div style='margin-top: -20px'>Complete the words. <img src='img/FriendsPlus/Page37/E2/1.jpg' style='height: 2.5cm; margin-left: 20px; margin-top: -10px'/></div>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 40px'>
            <span><b>1. </b>There’s a frog in the po<u style='color:gray; text-underline-offset: 5px'>nd&nbsp</u>.</span>
            <span><b>2. </b>She has a plant in her ha#.</span>
            <span><b>3. </b>I like sleeping in a te#.</span>
            <span><b>4. </b>He’s holding a la#.</span>
            <span><b>5. </b>There’s a ca# in the field.</span>
            <span><b>6. </b>There’s a pla# under the tree.</span>
        </div>
        `,
        answer: ["nd", "nt", "mp", "mp", "nt"],
      },
    ],
  },
  3: {
    id: "WB5-2024-U5-P37-E3",
    audio: "Audios/15-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/Key/E3answerKey.png",
    stylesTextInput: {
      width: 120,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and write. <span style='color: white'><headphone name='&nbsp 15' typeimg=sound src='Audios/15-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 26cm; margin-top: 10px; display: flex; gap: 15px'>
          <img src='img/FriendsPlus/Page37/E3/1.jpg' style='height: 9cm'/>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <span>We put up the <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbsptent&nbsp</u>,</span>
            <span>At the big, big <sup>2</sup>#.</span>
            <span>We hear the <sup>3</sup>#.</span>
            <span>We light the <sup>4</sup>#.</span>
            <span>We sit by the <sup>5</sup>#.</span>
            <span>We look at the <sup>6</sup>#.</span>
            <span>We’re happy together,</span>
            <span>Just me and my <sup>7</sup>#.</span>
          </div>
        </div>
        `,
        answer: ["camp", "wind", "lamp", "pond", "plants", "aunt"],
      },
    ],
  },
};

export default json;
