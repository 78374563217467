import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  3: {
    unit: "Review 4",
    id: "WB5-2024-R4-P87-E3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E3answerKey.png",
    // audio: "Audios/34-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: "Underline the words that sound stronger.",
        color: "#203c8f",
        left: 20,
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        // circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 3px",
          border: "none",
          background: "none",
          // fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "Are you going to play",
          "You must wear a mask",
          "Who did you meet at the park",
          "What are you going to do",
          "You should stay in bed",
          "What did you do on the weekend",
        ],
        answers: [
          "0-0",
          "0-2",
          "0-4",
          "1-1",
          "1-4",
          "2-0",
          "2-3",
          "3-0",
          "3-3",
          "3-5",
          "4-1",
          "4-4",
          "5-0",
          "5-3",
          "5-6",
        ],
        initialValue: ["0-0", "0-2", "0-4"],
      },
      Layout: `
      <div style='width: 25cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 40px'>
        <span><b>1. </b><input id=0 type='Circle'/>?</span>
        <span><b>4. </b><input id=3 type='Circle'/>.</span>
        <span><b>2. </b><input id=1 type='Circle'/>?</span>
        <span><b>5. </b><input id=4 type='Circle'/>?</span>
        <span><b>3. </b><input id=2 type='Circle'/>.</span>
        <span><b>6. </b><input id=5 type='Circle'/>?</span>
      </div>
        
        `,
    },
  },
  4: {
    unit: "Review 4",
    id: "WB5-2024-R4-P87-E4",
    // audio: "Audios/35-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E4answerKey.png",
    video: "",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Talk about what you are going to do this weekend.",
        color: "#2d408e",
        left: 85,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 200,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#253f8e",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      // marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "go to the park / beach",
          "go swimming",
          "watch a movie / TV",
          "play soccer",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <img style='width: 25cm; margin-top: 20px' src='img/FriendsPlus/Page87/E4/1.jpg'/>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Review 4",
    id: "WB5-2024-R4-P87-E5",
    // audio: "Audios/35-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E5answerKey.png",
    video: "",
    component: T6,
    // recorder: true,
    titleQuestion: [
      {
        num: "5",
        title: "Write what the children <i>should</i> or <i>shouldn't</i> do.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 200,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#253f8e",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      marginLeft: -35,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["bed", "dinner", "ice cream", "orange juice", "water"],
        width: "max-content",
        inline: true,
      },
    ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='width: 25cm; margin-top: 20px; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Mai has a headache. <u style='color:gray; text-underline-offset: 5px'>&nbspShe should drink some water.&nbsp</u></span>
          <span><b>2. </b>Linh has a toothache. <input width='664px'/></span>
          <span><b>3. </b>Tuan has a cold. <input width='723px'/></span>
          <span><b>4. </b>Vinh has a stomachache. <input width='626px'/></span>
          <span><b>5. </b>Thinh feels sick. <input width='727px'/></span>
        </div>
        `,
        answer: [
          "She shouldn't drink orange juice.",
          "He should go to bed.",
          "He shouldn't eat ice cream.",
          "He shouldn't eat dinner",
        ],
      },
    ],
  },
  6: {
    unit: "Review 4",
    id: "WB5-2024-R4-P87-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", paddingLeft: 0 },
    inputSize: 500,
    titleQuestion: [{}],
    hideBtnFooter: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        dontChangeColor: true,
        initialWordStyle: {
          display: "inline-block",
          border: "2px solid",
          borderColor: "transparent",
          padding: "20px 20px",
          borderRadius: "50%",
          color: "transparent",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: ["aaaaaaaa aaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaaaaaa"],
        answers: [],
        initialValue: [],
      },
      Layout: `
          <div style='width: 25cm; border: 2px solid rgb(116, 119, 176); box-shadow: 2px 2px rgb(183, 184, 179); padding: 40px 20px 20px 20px; border-radius: 10px; position: relative; margin-top: 50px'>
            <div style='position: absolute; top: -20px; left: 20px; background: rgb(37, 63, 142); padding: 5px 10px; border-radius: 10px; color: rgb(141, 215, 247); font-weight: 800'>My work</div>
            <div style='width: 25cm; display:flex; flex-direction: column'>
              <span>My favorite story in Units 10-12 is <input width='530px'></span>
              <span>My favorite song in Units 10-12 is <input width='532px'></span>
              <span>My favorite unit in Units 10-12 is <input width='543px'></span>
              <span>I need to practice <input width='686px'></span>
              <img style='width: 22cm; margin-top: 20px' src='img/FriendsPlus/Page87/E6/1.jpg'/>
              <div style='position: absolute; bottom: 10px; left: 10px; width: 22cm; display: flex; gap: 25px'><input id=0 type='Circle'/></div>
            </div>
          </div>
        `,
    },
  },
};

export default json;
