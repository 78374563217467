import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P73-E1",
    exerciseKey: "img/FriendsPlus/Page73/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: "23cm",
    maxLength: 80,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Complete the sentences with so and because.",
        color: "#203c8f",
        left: 55,
        top: 100,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#253f8e",
      color: "#57585a",
      styleElementHintBox: {
        margin: "0px 15px",
      },
    },
    hintBox: [
      {
        src: [
          "it’s bad for my teeth",
          "I eat them every day",
          "<span class='strikediag'>I don’t want to be tired at school</span>",
          "I play outside every day",
          "it’s good for my teeth",
        ],
        width: "25cm",
        // inline: true,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width: 25cm; margin-top: 20px; display: flex; flex-direction: column; gap: 7px'>
          <span><b>1. </b>I always go to bed early <u style='color:gray; text-underline-offset: 5px'>&nbspbecause I don’t want to be tired at school&nbsp</u>.</span>
          <span><b>2. </b>I don’t eat food with a lot of sugar <input width='521px'/>.</span>
          <span><b>3. </b>Exercise makes me stronger <input width='582px'/>.</span>
          <span><b>4. </b>I drink milk with my breakfast <input width='566px'/>.</span>
          <span><b>5. </b>Fruit and vegetables are good for you <input width='479px'/>.</span>
        </div>
        `,
        answer: [
          "because it's bad for my teeth",
          "so I play outside every day",
          "because it's good for my teeth",
          "so I eat them every day",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P73-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page73/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 23,
      width: 220,
    },
    textareaStyle: {
      // marginLeft: 7,
      background: "none",
      width: "100%",
      resize: "none",
      fontSize: 23,
      lineHeight: "2em",
      padding: 0,
      // border: "1px solid rgb(168, 168, 168)",
    },
    maxLength: 16,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 55,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 27cm; border: 1px solid rgb(220, 221, 223); font-size: 22px; box-shadow: 5px 5px rgb(233, 233, 233);padding: 20px'>
            <table style='width: 100%; table-layout: auto; border-collapse: collapse; color: rgb(0, 136, 202)'>
              <tr style='background: rgb(215, 230, 245); color: rgb(0, 136, 202)'>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202); font-weight: normal'>Morning</th>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202); font-weight: normal'>Lunch</th>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202); font-weight: normal'>After school</th>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202); font-weight: normal'>Evening</th>
              </tr>
              <tr>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'><span style='display: inline-block; width: 220px'>Food</span></td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>#</td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>#</td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>#</td>
              </tr>
              <tr>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>Exercise</td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>#</td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>#</td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'><input placeholder='Go to bed at ...?'/></td>
              </tr>
            </table>
          </div>
        `,
        InputRong: true,
        answer: [
          "Grandma, wolf, Red Riding Hood",
          "Red Riding Hood went to visit her Grandma.",
          "Red Riding Hood and the wolf met Grandma, who was scared of the wolf.",
          "Grandma made cookies and they lived happily ever after.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P73-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/Key/E3answerKey.png",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write about your healthy habits. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(116, 119, 176); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=8></textarea>
          </div>
        `,
        answer: [],
        fixedValue: ["My healthy habits"],
      },
    ],
  },
};
export default json;
