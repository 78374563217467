import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P31-E1",
    // audio: "Audios/09-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 200,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Say the words with ld and lt. Circle the odd-one-out. Write.",
        color: "#2d408e",
        left: 0,
      },
    ],
    component: Circle_Write,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "adult",
          "field",
          "child",
          "<span class='strikediag'>salt</span>",
          "shield",
          "belt",
        ],
        width: "max-content",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 150, textAlign: "center" },
        answers: ["adult", "field", "child"],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "16px 58px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
          fontSize: 30,
          color: "transparent",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-2", "1-0", "2-2"],
        initialValue: [],
      },
      Layout: `
        <hintbox id=0></hintbox>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><b>1. </b><img src='img/FriendsPlus/Page31/E1/1.jpg' style='width: 12cm'/> <u style='color:gray; text-underline-offset: 5px'>&nbspsalt&nbsp</u></div>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><div style='position: absolute; top: 0; left: 31px; display: flex; gap: 14px'><input id=0 type='Circle'/></div><b>2. </b><img src='img/FriendsPlus/Page31/E1/2.jpg' style='width: 12cm'/> #</div>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><div style='position: absolute; top: 0; left: 31px; display: flex; gap: 14px'><input id=1 type='Circle'/></div><b>3. </b><img src='img/FriendsPlus/Page31/E1/3.jpg' style='width: 12cm'/> #</div>
        <div style='margin-top: 15px; display: flex; gap: 10px; align-items: center; position: relative'><div style='position: absolute; top: 0; left: 31px; display: flex; gap: 14px'><input id=2 type='Circle'/></div><b>4. </b><img src='img/FriendsPlus/Page31/E1/4.jpg' style='width: 12cm'/> #</div>
        
      `,
    },
  },
  2: {
    id: "WB5-2024-U4-P31-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/Key/E2answerKey.png",
    stylesTextInput: {
      width: 50,
      padding: "10px 10px 10px 0",
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "<span style='display: inline-block; margin-top: -6px'>Complete the word. Write T (true) or F (false). <hintbox id=0></hintbox></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["ld", "lt"],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='width: 27cm; display: flex; gap: 15px'>
            <div style='display: grid; grid-template-columns: 5fr 1fr; grid-gap: 10px'>
              <span><b>1. </b>There's an adu<u style='color:gray; text-underline-offset: 5px'>lt&nbsp</u>  in the bedroom.</span><span><u style='color:gray; text-underline-offset: 5px; padding-left: 5px'>&nbsp&nbsp&nbspF&nbsp&nbsp&nbsp</u></span>
              <span><b>2. </b>There's a be<input textAlign='left'/> on the desk. </span><span><input padding="10px"></span>
              <span><b>3. </b>There's a shie<input textAlign='left'/> on the door. </span><span><input padding="10px"></span>
              <span><b>4. </b>There are some cows in the fie<input textAlign='left'/>. </span><span><input padding="10px"></span>
              <span><b>5. </b>There's a chi<input textAlign='left'/> in the bedroom. </span><span><input padding="10px"></span>
            </div>
            <img src='img/FriendsPlus/Page31/E2/1.jpg' style='height: 6cm'/>
          </div>
        `,
        answer: ["lt", "T", "ld", "F", "ld", "F", "ld", "T"],
      },
    ],
  },
  3: {
    id: "WB5-2024-U4-P31-E3",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/Key/E3answerKey.png",
    stylesTextInput: {
      width: 200,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "<span style='display: inline-block; margin-top: -6px'> Write. <hintbox id=0></hintbox></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "adult",
          "<span class='strikediag'>belt</span>",
          "shield",
          "child",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='width: 27cm; display: flex; gap: 15px; align-items: center'>
            <img src='img/FriendsPlus/Page31/E3/1.jpg' style='height: 6cm'/>
            <div style='display: flex; flex-direction: column; gap: 8px'>
              <span>The <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspbelt&nbsp</u> is red,</span>
              <span>The <sup>2</sup># is gray.. </span>
              <span>The <sup>3</sup># sits down. </span>
              <span>But the <sup>4</sup># wants to play. </span>
            </div>
          </div>
        `,
        answer: ["shield", "adult", "child"],
      },
    ],
  },
};

export default json;
