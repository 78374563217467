import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  3: {
    unit: "Unit 3",
    id: "WB5-2024-U1-P27-E3",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Circle the odd one out.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "loves walks sits",
          "balloons cookies hats",
          "cats vets last",
          "brushes rides catches",
        ],
        answers: ["0-0", "1-2", "2-2", "3-1"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap:20px 50px; margin-top: 10px'>
        <span style='display: flex'>
          <b style='padding-top: 3px'>1.&nbsp</b>
          <div style='flex-grow: 1; display:flex; justify-content: space-around'><input id=0 type='Circle'/></div>
        </span>
        <span style='display: flex'>
          <b style='padding-top: 3px'>3.&nbsp</b>
          <div style='flex-grow: 1; display:flex; justify-content: space-around'><input id=2 type='Circle'/></div>
        </span>
        <span style='display: flex'>
          <b style='padding-top: 3px'>2.&nbsp</b>
          <div style='flex-grow: 1; display:flex; justify-content: space-around'><input id=1 type='Circle'/></div>
        </span>
        <span style='display: flex'>
          <b style='padding-top: 3px'>4.&nbsp</b>
          <div style='flex-grow: 1; display:flex; justify-content: space-around'><input id=3 type='Circle'/></div>
        </span>
      </div>
      `,
    },
  },

  4: {
    unit: "Unit 3",
    id: "WB5-2024-U1-P27-E4",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E4answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "4",
        title: "Ask and answer.",
        color: "#2d408e",
        left: -30,
      },
    ],
    recorder: true,
    hideBtnFooter: true,
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          display: "inline-block",
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "where / often / go / Saturdays",
          "who / usually / meet / Sundays",
          "what / sometimes / do / weekend",
          "when / always / go_to_school / mornings",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='text-align: center'><img style='height: 3cm' src='img/FriendsPlus/Page27/E4/1.jpg'/></div>
      <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap:10px; margin-top: 20px'>
        <span>
          <b>1.&nbsp</b>
          where / often / go / Saturdays
        </span>
        <span>
          <b>3.&nbsp</b>
          who / usually / meet / Sundays
        </span>
        <span>
          <b>2.&nbsp</b>
          what / sometimes / do / weekend
        </span>
        <span>
          <b>4.&nbsp</b>
          when / always / go to school / mornings
        </span>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P27-E5",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", paddingLeft: 0 },
    inputSize: 500,
    titleQuestion: [
      {
        num: "5",
        title:
          "Write a paragraph of 30-40 words about your daily routine. Use the words to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; display:flex; flex-direction: column'>
            <span>First, <input width='876px'></span>
            <span>Then, <input width='868px'></span>
            <span>Next, <input width='872px'></span>
            <span>Finally, <input width='854px'></span>
          </div>
        `,
        answer: [],
        initialValue: [],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P27-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", paddingLeft: 0 },
    inputSize: 500,
    titleQuestion: [{}],
    hideBtnFooter: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        dontChangeColor: true,
        initialWordStyle: {
          display: "inline-block",
          border: "2px solid",
          borderColor: "transparent",
          padding: "20px 20px",
          borderRadius: "50%",
          color: "transparent",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: ["aaaaaaaa aaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaaaaaa"],
        answers: [],
        initialValue: [],
      },
      Layout: `
          <div style='width: 25cm; border: 2px solid rgb(101, 193, 139); box-shadow: 2px 2px rgb(183, 184, 179); padding: 40px 20px 20px 20px; border-radius: 10px; position: relative; margin-top: 50px'>
            <div style='position: absolute; top: -20px; left: 20px; background: rgb(0, 165, 79); padding: 5px 10px; border-radius: 10px; color: rgb(218, 233, 192); font-weight: 800'>My work</div>
            <div style='width: 25cm; display:flex; flex-direction: column'>
              <span>My favorite story in Units 1-3 is <input width='530px'></span>
              <span>Then, <input width='824px'></span>
              <span>My favorite song in Units 1-3 is <input width='532px'></span>
              <span>My favorite unit in Units 1-3 is <input width='543px'></span>
              <span>I need to practice <input width='686px'></span>
              <img style='width: 22cm; margin-top: 10px' src='img/FriendsPlus/Page27/E5/1.jpg'/>
              <div style='position: absolute; bottom: 10px; left: 10px; width: 22cm; display: flex; gap: 25px'><input id=0 type='Circle'/></div>
            </div>
          </div>
        `,
    },
  },
};

export default json;
