import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P69-E1",
    // audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page69/Key/E1answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Read and circle.",
        color: "#203c8f",
        left: -35,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: [
          "should /<br> shouldn’t",
          "should / shouldn’t",
          "should / shouldn’t",
          "should / shouldn’t",
          "should / shouldn’t",
          "should / shouldn’t",
          "should / shouldn’t",
        ],
        answers: ["0-0", "1-0", "2-2", "3-2", "4-0", "5-2", "6-0"],
        initialValue: ["0-0"],
      },
      Layout: `
        <div style='width: 26cm; display: flex; flex-direction: column; gap: 5px; position: relative'>
          <span><b>1. </b>On school days, you <input id=0 type='Circle'/> get up early.</span>
          <span><b>2. </b>You <input id=1 type='Circle'/> get<br>dressed quickly.</span>
          <span><b>3. </b>You <input id=2 type='Circle'/> run <br>in the kitchen.</span>
          <span><b>4. </b>You <input id=3 type='Circle'/> be late <br>for school.</span>
          <span><b>5. </b>You <input id=4 type='Circle'/> listen politely to the teacher.</span>
          <span><b>6. </b>You <input id=5 type='Circle'/> talk when the teacher is talking.</span>
          <span><b>7. </b>You <input id=6 type='Circle'/> always try your hardest.</span>
          <img style='width: 14cm; position: absolute; top: 20px; right: 0' src='img/FriendsPlus/Page69/E1/1.jpg'/>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P69-E2",
    exerciseKey: "img/FriendsPlus/Page69/Key/E2answerKey.png",
    // audio: "Audios/28-audio.mp3",
    video: "",
    inputSize: 450,
    maxLength: 40,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Look and write.",
        color: "#203c8f",
        left: 50,
      },
    ],
    component: T6,
    stylesTextInput: {
      fontSize: 23,
    },
    questions: [
      {
        title: `
        <div style='width: 26cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 40px'>
          <div>
            <img style='width: 100%' src='img/FriendsPlus/Page69/E2/1.jpg'/>
            <span>Mai has a cold, so she <u style='color:gray; text-underline-offset: 5px'>&nbspshouldn’t go to school&nbsp</u>. She <u style='color:gray; text-underline-offset: 5px'>&nbspshould drink some orange juice.&nbsp</u> </span>
          </div>
          <div>
            <img style='width: 100%' src='img/FriendsPlus/Page69/E2/2.jpg'/>
            <span>Lizzy has an earache, so she #.<br>She <input width='400px'/>. </span>
          </div>
          <div>
            <img style='width: 100%' src='img/FriendsPlus/Page69/E2/3.jpg'/>
            <span>Alex has a stomachache, so he<br>#. He <input width='415px'/>.</span>
          </div>
          <div>
            <img style='width: 100%' src='img/FriendsPlus/Page69/E2/4.jpg'/>
            <span>Thanh has a fever, so he #.<br>He <input width='415px'/>.</span>
          </div>
        </div>
        `,
        answer: [
          "shouldn't listen to loud music",
          "should see a doctor",
          "shouldn't eat cakes",
          "should stay in bed",
          " shouldn't go outside",
          "should drink water",
        ],
      },
    ],
  },
};

export default json;
