import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  3: {
    unit: "R3",
    id: "WB5-2024-R3-P67-E3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E3answerKey.png",
    // audio: "Audios/27-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: "Underline the stronger sounds.",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // newSplit: true,
        initialWordStyle: {
          padding: "1px 0px",
          border: "none",
          background: "none",
          fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "It’s deep|er than a lake",
          "It’s long|er than a snake",
          "Did you have a snack",
          "No, they didn’t",
          "The snake moves quick|ly",
          "The tiger walks quiet|ly",
        ],
        answers: [
          "0-2",
          "0-10",
          "1-2",
          "1-10",
          "2-2",
          "2-8",
          "3-0",
          "3-4",
          "4-2",
          "4-6",
          "5-2",
          "5-6",
        ],
        initialValue: ["0-2", "0-10"],
      },
      Layout: `
        <div style='position: relative; width: 25cm; height: 7cm'>
          <div style='width: 17cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 20px; z-index: 1; position: relative'>
            <span><b>1. </b><input id=0 type='Circle'/>.</span>
            <span><b>4. </b><input id=3 type='Circle'/>.</span>
            <span><b>2. </b><input id=1 type='Circle'/>.</span>
            <span><b>5. </b><input id=4 type='Circle'/>.</span>
            <span><b>3. </b><input id=2 type='Circle'/>?</span>
            <span><b>6. </b><input id=5 type='Circle'/>.</span>
          </div>
          <img style='width: 25cm; position: absolute; left: 0;top: -30px' src='img/FriendsPlus/Page67/E3/1.png'/>
        </div>
        `,
    },
  },
  4: {
    unit: "R3",
    id: "WB5-2024-R3-P67-E4",
    exerciseKey: "img/FriendsPlus/Page67/Key/E4answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    // maxLength: 40,
    recorder: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "4",
        title: "Talk about your weekend.",
        color: "#203c8f",
        left: 82,
      },
    ],
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#ee1d23",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      // marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["museum", "park", "zoo", "playground", "a lake", "the mountains"],
        width: "max-content",
        inline: true,
      },
      {
        src: [
          "Did you go …?",
          "Did you play … ?",
          "Did you see …?",
          "Did you have … ?",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; flex-direction: column; gap: 15px'>
          <hintbox id=0></hintbox>
          <hintbox id=1></hintbox>
          <img style='width: 25cm' src='img/FriendsPlus/Page67/E4/1.jpg'/>
        </div>
        `,
        answer: ["faster than", "higher than ", "deeper than"],
      },
    ],
  },
  5: {
    unit: "R3",
    id: "WB5-2024-R3-P67-E5",
    exerciseKey: "img/FriendsPlus/Page67/Key/E5answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    // maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "5",
        title:
          "Write a paragraph of 30–40 words about a place you visited. What did you see and do?",
        color: "#203c8f",
        left: 60,
      },
    ],
    component: T6,
    textareaStyle: {
      width: "25cm",
      resize: "none",
    },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=3></textarea>
        `,
        answer: ["faster than", "higher than ", "deeper than"],
      },
    ],
  },
  6: {
    unit: "R3",
    id: "WB5-2024-R3-P67-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", paddingLeft: 0 },
    inputSize: 500,
    titleQuestion: [{}],
    hideBtnFooter: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        dontChangeColor: true,
        initialWordStyle: {
          display: "inline-block",
          border: "2px solid",
          borderColor: "transparent",
          padding: "20px 20px",
          borderRadius: "50%",
          color: "transparent",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: ["aaaaaaaa aaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaaaaaa"],
        answers: [],
        initialValue: [],
      },
      Layout: `
          <div style='width: 25cm; border: 2px solid rgb(244, 132, 101); box-shadow: 2px 2px rgb(183, 184, 179); padding: 40px 20px 20px 20px; border-radius: 10px; position: relative; margin-top: 50px'>
            <div style='position: absolute; top: -20px; left: 20px; background: rgb(238, 29, 35); padding: 5px 10px; border-radius: 10px; color: rgb(252, 210, 191); font-weight: 800'>My work</div>
            <div style='width: 25cm; display:flex; flex-direction: column'>
              <span>My favorite story in Units 7-9 is <input width='530px'></span>
              <span>My favorite song in Units 7-9 is <input width='532px'></span>
              <span>My favorite unit in Units 7-9 is <input width='543px'></span>
              <span>I need to practice <input width='686px'></span>
              <img style='width: 22cm; margin-top: 20px' src='img/FriendsPlus/Page67/E6/1.jpg'/>
              <div style='position: absolute; bottom: 10px; left: 10px; width: 22cm; display: flex; gap: 25px'><input id=0 type='Circle'/></div>
            </div>
          </div>
        `,
    },
  },
};

export default json;
