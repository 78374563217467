import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P74-E1",
    exerciseKey: "img/FriendsPlus/Page74/Key/E1answerKey.png",
    audio: "",
    video: "",
    // inputSize: "23cm",
    maxLength: 1,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Read the sentences and number the picture.",
        color: "#203c8f",
        left: 55,
        top: 100,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    stylesTextInput: {
      width: 30,
      height: 30,
      border: "none",
      padding: "0 6px",
      // margin: 0,
      background: "none",
    },
    questions: [
      {
        title: `
        <div style='position: relative'>
          <img style='width: 25cm' src='img/FriendsPlus/Page74/E1/1.jpg'/>
          <div style='position: absolute; top: 96px; left: 22px'>#</div>
          <div style='position: absolute; top: 123px; left: 730px'>#</div>
          <div style='position: absolute; top: 308px; left: 240px'>#</div>
          <div style='position: absolute; top: 345px; left: 413px'>#</div>
          <div style='position: absolute; top: 452px; left: 734px'>#</div>
        </div>
        <div style='margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr; width: 25cm; grid-gap: 7px 20px'>
          <span><b>1. </b>These people travel in space.</span>
          <span><b>4. </b>We all live on this.</span>
          <span><b>2. </b>Things that will happen later, not now.</span>
          <span><b>5. </b>A big ship that travels around in space.</span>
          <span><b>3. </b>It’s long and carries astronauts.</span>
          <span><b>6. </b>You can see it in the sky at night. </span>
        </div>
        `,
        answer: ["2", "3", "6", "4", "5"],
      },
    ],
  },
  2: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P74-E2",
    exerciseKey: "img/FriendsPlus/Page74/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 80,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write the correct word next to numbers 1–8.",
        color: "#203c8f",
        left: 55,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#253f8e",
      color: "#57585a",
      styleElementHintBox: {
        margin: "0px 15px",
      },
    },
    hintBox: [
      {
        src: [
          "travel",
          "<span class='strikediag'>future</span>",
          "supersonic planes",
          "moon",
          "astronaut",
          "rockets",
          "spaceships",
          "Earth",
        ],
        width: "16cm",
        // inline: true,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px; align-items: center'>
          <hintbox id=0></hintbox>
          <span>In the <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspfuture&nbsp</u>, you won’t have to be an <sup>2</sup># to <sup>3</sup># in space. Long <sup>4</sup># will take off from <sup>5</sup># with people inside. Some people will travel in big <sup>6</sup><input width='230px'/> or <sup>7</sup><input width='230px'/> . The people will look out of the windows and see the big round <sup>8</sup># .</span>
        </div>
        `,
        answer: [
          "astronaut",
          "travel",
          "rockets",
          "Earth",
          "spaceships|supersonic planes",
          "supersonic planes|spaceships",
          "moon",
        ],
      },
    ],
  },
};

export default json;
