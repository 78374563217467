import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P89-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page89/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Order the words. Write true sentences about you. Use <i>always, usually, often,<br>sometimes</i>, or <i>never</i>.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 400,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      gap: "5px",
      paddingInline: 0,
      // borderColor: "#2bbbf1",
      // color: "#57585a",
      styleElementHintBox: {
        background: "#c6eafa",
        padding: "2px 5px",
        borderRadius: "5px",
        margin: 0,
      },
    },
    hintBox: [
      {
        src: ["have", "family", ".", "I", "with", "breakfast", "my"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["homework", "in", "I", "my", "do", "the", "morning", "."],
        width: "max-content",
        inline: true,
      },
      {
        src: ["at", "brush", "I", "my", "teeth", "night", "."],
        width: "max-content",
        inline: true,
      },
      {
        src: ["play", "I", "school", ".", "soccer", "after"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["to", "school", "walk", "I", "the", "morning", ".", "in"],
        width: "max-content",
        inline: true,
      },
    ],
    // character: "/",
    // hai , ba , bốn , năm
    checkUppercase: true,
    stylesTextInput: {
      background: "none",
    },
    textareaStyle: {
      width: 400,
      resize: "none",
      paddingTop: 5,
    },
    questions: [
      {
        title: `
        <div style='width: 25cm; display:grid; grid-template-columns: 2fr 1fr; grid-gap: 10px 20px'>
          <span><b>1. </b><hintbox id=0></hintbox></span> <u style='margin-top: 15px; color:gray; text-underline-offset: 3px'>&nbsp&nbspI always have breakfast with my family.&nbsp&nbsp</u>
          <span><b>2. </b><hintbox id=1></hintbox></span><textarea id=0 rows=3></textarea>
          <span><b>3. </b><hintbox id=2></hintbox></span><textarea id=1 rows=3></textarea>
          <span><b>4. </b><hintbox id=3></hintbox></span><textarea id=2 rows=3></textarea>
          <span><b>4. </b><hintbox id=3></hintbox></span><textarea id=3 rows=3></textarea>
        </div>
        `,
        answer: [
          "I always do my homework in the morning.|I usually do my homework in the morning.|I often do my homework in the morning.|I sometimes do my homework in the morning.|I never do my homework in the morning.",
          "I always brush my teeth at night.|I usually brush my teeth at night.|I often brush my teeth at night.|I sometimes brush my teeth at night.|I never brush my teeth at night.",
          "I always play soccer after school.|I usually play soccer after school.|I often play soccer after school.|I sometimes play soccer after school.|I never play soccer after school.",
          "I always walk to school in the morning.|I usually walk to school in the morning.|I often walk to school in the morning.|I sometimes walk to school in the morning.|I never walk to school in the morning.",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P89-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page89/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the question with <i>on, at</i>, or <i>in</i>. Then answer the question.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 100,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      gap: "5px",
      paddingInline: 0,
      // borderColor: "#2bbbf1",
      // color: "#57585a",
      styleElementHintBox: {
        background: "#c6eafa",
        padding: "2px 5px",
        borderRadius: "5px",
        margin: 0,
      },
    },
    hintBox: [
      {
        src: ["have", "family", ".", "I", "with", "breakfast", "my"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["homework", "in", "I", "my", "do", "the", "morning", "."],
        width: "max-content",
        inline: true,
      },
      {
        src: ["at", "brush", "I", "my", "teeth", "night", "."],
        width: "max-content",
        inline: true,
      },
      {
        src: ["play", "I", "school", ".", "soccer", "after"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["to", "school", "walk", "I", "the", "morning", ".", "in"],
        width: "max-content",
        inline: true,
      },
    ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
    },
    textareaStyle: {
      width: 400,
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:grid; grid-template-columns: 2fr 1fr; grid-gap: 10px 20px'>
          <span><b>1. </b>Do you go to school <u style='margin-top: 15px; color:gray; text-underline-offset: 5px'>&nbspin&nbsp</u> February?</span><u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspYes, I do.&nbsp&nbsp</u>
          <span><b>2. </b>Does school start # nine o’clock? </span><input width='300px' textalign='left'/>
          <span><b>3. </b>Do you get up late # Saturdays? </span><input width='300px' textalign='left'/>
          <span><b>4. </b>Is your birthday # November? </span><input width='300px' textalign='left'/>
          <span><b>5. </b>Do you watch TV # the morning?</span><input width='300px' textalign='left'/>
        </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P89-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page89/Key/E3answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Write four questions using <i>on, at</i>, or <i>in</i>.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: "24cm",
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["have", "family", ".", "I", "with", "breakfast", "my"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["homework", "in", "I", "my", "do", "the", "morning", "."],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["at", "brush", "I", "my", "teeth", "night", "."],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["play", "I", "school", ".", "soccer", "after"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["to", "school", "walk", "I", "the", "morning", ".", "in"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
    },
    textareaStyle: {
      width: 400,
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap 10px'>
          <span><b>1. </b>Do you <input width='calc(24cm - 88px)'/></span>
          <span><b>2. </b>#</span>
          <span><b>3. </b>#</span>
          <span><b>4. </b>#</span>
        </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
