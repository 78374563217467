import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P56-E1",
    // audio: "Audios/13-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page56/Key/E1answerKey.png",
    // stylesTextInput: {
    //   width: 300,
    // },
    titleQuestion: [
      {
        num: "1",
        title: "Match the questions with the answers.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        // lineDirection: "row",
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: `
              <div style='display: flex; gap: 5px'><span>1. Is a grape larger than a melon?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 0
          {
            children: `
              <div style='display: flex; gap: 5px'><span>2. What is taller than a tower?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 1
          {
            children: `
              <div style='display: flex; gap: 5px'><span>3. Is a horse slower than a cheetah?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 2
          {
            children: `
              <div style='display: flex; gap: 5px'><span>4. What is smaller than a mouse?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 3
          {
            children: `
              <div style='display: flex; gap: 5px'><span>5. Is a lion taller than a giraffe?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 4
          {
            children: `
              <div style='display: flex; gap: 5px'><span>6. What is faster than a train?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 5
          {
            children: `
              a. A mountain.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 6
          {
            children: `
              b.  No, it isn’t.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 7
          {
            children: `
              c. No, it isn’t.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 8
          {
            children: `
              d. A plane
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 9
          {
            children: `
              e. A grape
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 10
          {
            children: `
              f. Yes, it is.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 11
        ],
        answers: ["0-8", "1-6", "2-11", "3-10", "4-7", "5-9"],
        initialValue: ["0-8"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; gap: 300px'>
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
          <input id='5' type='boxMatch' />
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <input id='9' type='boxMatch' />
          <input id='10' type='boxMatch' />
          <input id='11' type='boxMatch' />
        </div>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P56-E2",
    exerciseKey: "img/FriendsPlus/Page56/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Look at the chart. Circle the mistake in each sentence. Write the correct sentence.",
        color: "#203c8f",
        left: -35,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 800, marginLeft: 25, fontSize: 25 },
        answers: [
          "A mouse is shorter than a horse.",
          "Australia is larger than Singapore.",
          "A plane is faster than a car.",
          "Viet Nam is smaller than Australia.",
          "A giraffe is taller than a horse.",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 1,
          border: "2px solid",
          borderColor: "transparent",
          borderRadius: "50%",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: [
          "A mouse is taller than a horse",
          "Australia is smaller than Singapore",
          "A plane is slower than a car",
          "Viet Nam is larger than Australia",
          "A giraffe is shorter than a horse",
        ],
        answers: ["0-3", "1-2", "2-3", "3-3", "4-3"],
        initialValue: [],
      },
      Layout: `
      <img style='width: 20cm' src='img/FriendsPlus/Page56/E2/1.jpg' />
      <div style='display: flex; flex-direction: column; gap: 5px;'>
        <span><b>1. </b>A train is <span style='padding: 1px; border: 2px solid rgb(0, 174, 239); border-radius: 50%'>faster</span> than a plane.</span>
        <u style='margin-left: 25px; color:gray; text-underline-offset: 5px'>&nbspA train is slower than a plane.&nbsp</u>
        <span><b>2. </b><input id=0 type='Circle'/></span>
        #
        <span><b>3. </b><input id=1 type='Circle'/></span>
        #
        <span><b>4. </b><input id=2 type='Circle'/></span>
        #
        <span><b>5. </b><input id=3 type='Circle'/></span>
        #
        <span><b>6. </b><input id=4 type='Circle'/></span>
        #
      </div>
      `,
    },
  },
};

export default json;
