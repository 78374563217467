import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P22-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page22/Key/E1answerKey.png",
    stylesTextInput: {
      width: "100%",
      borderWidth: "0 0 1px",
      // background: "blue",
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Complete the question with a or some and a food word.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 25cm; position: relative'>
          <div style='position: absolute; top: 156px; left: 212px; width:259px'>#</div>
          <div style='position: absolute; top: 232px; left: 211px; width:281px'>#</div>
          <div style='position: absolute; top: 309px; left: 213px; width:263px'>#</div>
          <div style='position: absolute; top: 383px; left: 211px; width:280px'>#</div>
          <img style='width: 100%' src='img/FriendsPlus/Page22/E1/1.jpg'/>
        </div>
        
        `,
        answer: [
          "some meat|some noodles",
          "some noodles|some meat",
          "some mushrooms|a cucumber",
          "a cucumber|some mushrooms",
        ],
        initialValue: [],
      },
    ],
  },

  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P22-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page22/Key/E1answerKey.png",
    stylesTextInput: {
      width: "100%",
      borderWidth: "0 0 1px",
      // background: "blue",
    },
    // textAlign: "center",
    textareaStyle: {
      resize: "none",
      width: "100%",
    },
    maxLength: 70,
    titleQuestion: [
      {
        num: "2",
        title:
          "What would you like for dinner? Write a, an, or some and food words.",
        color: "#2d408e",
        left: 60,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; gap: 20px'>
                  

          <div style='flex-grow: 1'><textarea placeholder="I'd like
          
          
                                                           for dinner." id=0 rows=4></textarea></div>
          <img style='height: 4cm' src='img/FriendsPlus/Page22/E2/1.jpg'/>
        </div>  
        `,
        answer: [],
        initialValue: [],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P22-E3",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page22/Key/E3answerKey.png",
    stylesTextInput: {
      padding: 0,
      border: "none",
    },
    maxLength: 2,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the song in the Student Book. Put the numbers 1 to 12 in the correct box.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; gap: 5px; justify-content: space-between; align-items: center'>
          <img style='height: 13cm' src='img/FriendsPlus/Page22/E3/1.jpg'/>
          <div style='display: grid; grid-template-columns: 5fr 1fr; grid-gap: 5px 10px'>
            <span><b>a. </b>We buy things to eat.</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>b. </b>Could we have some meat?</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>c. </b>Apples, bread, and cookies,</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>d. </b>Could we have a melon?</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>e. </b>They’re very fresh and sweet.</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>f. </b>Could we have a cucumber?</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>g. </b>At the supermarket</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>h. </b>Could we have some apples?</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>i. </b>They’re healthy and they’re nice.</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>j. </b>Could we have some onions?</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'><input color='gray'/></div>
            <span><b>k. </b>Noodles, rice, and meat.</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
            <span><b>l. </b>Could we have some rice?</span> <div style='display: inline-block; width: 40px; height: 40px; border: 1px solid black; border-radius: 5px'>#</div>
          </div>
        </div>
        
        `,
        answer: ["6", "2", "7", "3", "4", "9", "5", "11", "12", "1", "8", "10"],
        initialValue: ["", "", "", "", "", "", "", "", "", "1"],
      },
    ],
  },
};

export default json;
