import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P41-E1",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page41/Key/E1answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the email. Choose the right word and write it on the line.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: true,
        colorLineTo: "#40c7f4",
        // newPointDot: true,
        // notOverlapse: true,
        circlePadding: "5 10",
        // margin: "0 0 0 10px",
        padding: "4px 0px 4px 15px",
        border: "1px solid",
        // transform: "translate(50%)",
        circleStringArray: [
          "opqxwdmn",
          "wheyafad",
          "uawvnilm",
          "startnok",
          "eebilive",
          "cbjmksep",
          "xtzbahft",
          "cookohur",
        ],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 200, textAlign: "center" },
        answers: ["used", "cooked", "started", "didn't", "finished"],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          // fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "did didn't do",
          "start started didn't_start",
          "use using used",
          "do did didn't",
          "cooked cook didn't_cook",
          "finished finish didn't_finish",
        ],
        answers: ["0-1", "2-2", "4-0", "1-1", "3-2", "5-0"],
        initialValue: ["0-1"],
      },
      Layout: `
      <div style='width: 26cm; padding: 15px; border: 1px solid blue'>
              Dear Long,
        Yesterday was my first day at my new school. At first I <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspdidn't&nbsp</u>
        didn't want to go.
        I was scared, but it was great! First, we <sup>2</sup>#
        the computers to 
        do some writing. Then we <sup>3</sup>#
        some food. We made soup!
        In the afternoon, we <sup>4</sup>#
        reading a new book. I really liked it. 
        We did some math. The teacher helped me, so I <sup>5</sup>#
        hate it!
        And the best thing? We <sup>6</sup>#
        school at 3 o'clock. Ten minutes 
        earlier than my old school!<br>
        Please email me back.<br>
        Best,<br>
        Tam
      </div>
      <div style='width: 26cm; margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr'>
        <div style='display: flex; gap: 5px'><b>1. </b><div style='width: 80%; display: flex; justify-content: space-around; gap: 10px; margin-top: -4px'><input id=0 type='Circle'></div></div>
        <div style='display: flex; gap: 5px'><b>4. </b><div style='width: 80%; display: flex; justify-content: space-around; gap: 10px; margin-top: -4px'><input id=1 type='Circle'></div></div>
        <div style='display: flex; gap: 5px'><b>2. </b><div style='width: 80%; display: flex; justify-content: space-around; gap: 10px; margin-top: -4px'><input id=2 type='Circle'></div></div>
        <div style='display: flex; gap: 5px'><b>5. </b><div style='width: 80%; display: flex; justify-content: space-around; gap: 10px; margin-top: -4px'><input id=3 type='Circle'></div></div>
        <div style='display: flex; gap: 5px'><b>3. </b><div style='width: 80%; display: flex; justify-content: space-around; gap: 10px; margin-top: -4px'><input id=4 type='Circle'></div></div>
        <div style='display: flex; gap: 5px'><b>1. </b><div style='width: 80%; display: flex; justify-content: space-around; gap: 10px; margin-top: -4px'><input id=5 type='Circle'></div></div>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P41-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page41/Key/E2answerKey.png",
    stylesTextInput: {
      width: 300,
      // paddingLeft: 0,
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write the answers.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <img src='img/FriendsPlus/Page41/E2/1.jpg' style='width: 100%'>
            <span><b>1. </b>Did he watch TV on Sunday?<u style='color:gray; text-underline-offset: 5px'>&nbspNo, he didn't.&nbsp</u>.</span>
            <span><b>2. </b>Did he start reading a book on Saturday? #</span>
            <span><b>3. </b>Did he finish reading the book on Sunday? #</span>
            <span><b>4. </b>Did he cook in the kitchen on Sunday? #</span>
            <span><b>5. </b>Did he help his dad on Saturday? #</span>
            <span><b>6. </b>Did he play computer games on Sunday? #</span>
        </div>
        `,
        answer: [
          "Yes, he did.",
          "No, he didn't.",
          "No, he didn't.",
          "No, he didn't.",
          "Yes, he did.",
        ],
      },
    ],
  },
};

export default json;
