import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P12-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page12/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 150,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Read the text in the Student Book. Write.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#1eb26a",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "Next",
          "<span class='strikediag'>every day</span>",
          "First",
          "Then",
        ],
        width: "12cm",
      },
    ],
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='display: flex; gap: 10px; flex-direction: column'>
            <span><b>1. </b> Sung Ni gets up early <u style='color:gray; text-underline-offset: 5px'>&nbspevery day&nbsp</u>.</span>
            <span><b>2. </b> #, she walks for an hour to get to school</span>
            <span><b>3. </b> #, she has her lessons</span>
            <span><b>4. </b> #, she walks home and helps her parents on the farm.</span>
          </div>
        `,
        answer: ["First", "Next", "Then"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P12-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page12/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 150,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Read the story. Choose a word from the box and write.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width:25cm'>
          <p>My name’s Duy. On Monday mornings, I don’t go to the park. I go to school. First, I brush my <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspteeth&nbsp</u></span>. Next, I catch the <sup>2</sup># to school with my friend Hong. Hong does her <sup>3</sup># after school. I don’t have lunch at school, but I have a <sup>4</sup># . I like school.</p>
          <div style='padding: 20px 80px; border: 5px solid rgb(151, 209, 172); border-radius: 20px'><img style='width: 100%' src='img/FriendsPlus/Page12/E2/1.jpg'/></div>
        </div>
        `,
        answer: ["bus", "homework", "snack"],
      },
    ],
  },
};

export default json;
