import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-Starter-P6-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page6/Key/E1answerKey.png",
    video: "",
    titleQuestion: [
      {
        num: "1",
        title: "Look and match.",
        color: "#2d408e",
        left: 70,
      },
    ],
    // stylesTextInput: {  },
    textAlign: "center",
    maxLength: 1,
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        multipleLine: false,
        colorLineTo: "#43caf1",
        widthLineto: 3,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "200px",
              left: "181px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "336px",
              left: "122px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "465px",
              left: "457px",
              width: "197px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "93px",
              left: "454px",
              width: "174px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "199px",
              left: "455px",
              width: "108px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "252px",
              left: "453px",
              width: "188px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "196px",
              left: "797px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "733px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "331px",
              left: "742px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "469px",
              left: "807px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "474px",
              left: "165px",
              width: "187px",
              height: "110px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "359px",
              left: "455px",
              width: "202px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "411px",
              left: "454px",
              width: "122px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",

              // background: "white",
            },
          }, // 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "303px",
              left: "454px",
              width: "124px",
              height: "41px",
              borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 14
        ],
        answers: ["10-3", "4-7", "5-8", "1-13", "0-11", "12-9", "2-6"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
          zIndex: 1,
        },
        answers: ["d", "e", "g", "c", "b", "h", "f"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "school_trip / boat_trip.",
          "movie_theater / museum.",
          "train / bus.",
          "four / five.",
          " postcard / dinosaur_model.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative'>
        <input id='0' type='boxMatch' />
        <input id='1' type='boxMatch' />
        <input id='2' type='boxMatch' />
        <input id='3' type='boxMatch' />
        <input id='4' type='boxMatch' />
        <input id='5' type='boxMatch' />
        <input id='6' type='boxMatch' />
        <input id='7' type='boxMatch' />
        <input id='8' type='boxMatch' />
        <input id='9' type='boxMatch' />
        <input id='10' type='boxMatch' />
        <input id='11' type='boxMatch' />
        <input id='12' type='boxMatch' />
        <input id='13' type='boxMatch' />
        <div style='position:absolute;top:71px;left:506px'> # </div>
        <div style='position:absolute;top:176px;left:471px'> # </div>
        <div style='position:absolute;top:231px;left:510px'> # </div>
        <div style='position:absolute;top:283px;left:475px'> # </div>
        <div style='position:absolute;top:337px;left:519px'> # </div>        
        <div style='position:absolute;top:390px;left:480px'> # </div>
        <div style='position:absolute;top:443px;left:516px'> # </div>

        <img style='height:14cm;' src='img/FriendsPlus/Page6/E1/1.jpg'/>
      </div>
      `,
    },
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P6-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page6/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: "Write the next number.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // isHello: true,
    inputSize: 200,
    styleHint: {
      color: "#a3969a",
      padding: 0,
      styleElementHintBox: {
        textDecoration: "underline",
        textUnderlineOffset: "5px",
      },
    },
    hintBox: [{ src: ["&nbsptwenty-three&nbsp"] }],
    questions: [
      {
        title: `
        <div style='display: flex; flex-direction: column'>
          <span style="display: flex; gap: 5px"><b>1. </b>twenty-one, twenty-two, <hintbox id=0></hintbox></span>
          <span><b>2. </b>eight, nine, #</span>
          <span><b>3. </b>thirty-four, thirty-five, #</span>
          <span><b>4. </b>sixty-seven, sixty-eight, #</span>
          <span><b>5. </b>thirteen, fourteen, #</span>
        </div>
        `,
        answer: ["ten ", "thirty-six ", "sixty-nine", "fifteen"],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "WB5-2024-Starter-P6-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page6/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Write a tourist leaflet. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    textareaStyle: {
      width: 500,
      position: "absolute",
      top: "69px",
      left: "28px",
      background: "transparent",
      lineHeight: "2.6em",
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='position: relative; height: 12cm'>
          <img style='height:12cm; position: absolute; top: 0; left: 0' src='img/FriendsPlus/Page6/E3/1.jpg'/>
				  <textarea id=0 rows=5 ></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
