import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    id: "WB5-2024-U5-P36-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page36/Key/E1answerKey.png",
    stylesTextInput: {
      width: 150,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the picture and read the story. Complete the sentences.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 24cm; text-align: center'><img style='width: 15cm' src='img/FriendsPlus/Page36/E1/1.jpg'/></div>
        <div style='width: 24cm; margin-top: 10px'>My name is Nga. On Saturday my family was at the park. It was warm and  windy. My sister was in the fountain! My dad was wet. He wasn't happy.  Mom was hungry, so she had a sandwich. My brother and I were on the swings. I had a really good time at the park!</div>
          <div style='width: 24cm; margin-top: 10px; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 40px'>
            <span><b>1. </b>Nga and her family <u style='color:gray; text-underline-offset: 5px'>&nbspweren't&nbsp</u> at the beach.</span>
            <span><b>4. </b>Mom # a sandwich. She # a drink.</span>
            <span><b>2. </b>The weather # windy.</span>
            <span><b>5. </b>The children # sad. They # happy.</span>
            <span><b>3. </b>Dad # happy. He # wet!</span>
            <span><b>6. </b>They # a good time.</span>
          </div>
        `,
        answer: [
          "had",
          "didn't have",
          "was",
          "weren't",
          "were",
          "wasn't",
          "was",
          "had",
        ],
      },
    ],
  },
  2: {
    id: "WB5-2024-U5-P36-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page36/Key/E2answerKey.png",
    stylesTextInput: {
      width: "100%",
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Write true sentences about you. Use was, wasn’t, had, or didn’t have.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px'>
            <span>Last year, <input width='800px'/>.</span>
            <span>Last week, <input width='792px'/>.</span>
            <span>Yesterday, <input width='792px'/>.</span>
        </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    id: "WB5-2024-U5-P36-E3",
    audio: "Audios/14-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page36/Key/E3answerKey.png",
    stylesTextInput: {
      width: 100,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and complete the song with was, were, have, or had. <span style='color: white'><headphone name='&nbsp 14' typeimg=sound src='Audios/14-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; text-align: center'><b style='font-size: 27px; color: rgb(0, 169, 157)'>Where were you on vacation?</b></div>
        <div style='width: 25cm; margin-top: 10px; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 40px'>
          <span>Where <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspwere&nbsp</u> you on vacation?<br>Did you <sup>2</sup># lots to do? <br>Where were you on vacation?<br><sup>3</sup># your family there with you?<br>We <sup>4</sup># in France on vacation.<br>We didn’t <sup>5</sup># lots to do.<br>We <sup>6</sup># great fun in the city. <br>My cousins were with us, too.</span>
          <span>We didn’t <sup>7</sup># any homework.<br>The weather <sup>8</sup># sunny and hot.<br>We <sup>9</sup># lots of delicious food.<br>I really miss it a lot!<br></span>
        </div>
        `,
        answer: ["have", "Was", "were", "have", "had", "have", "was", "had"],
      },
    ],
  },
};

export default json;
