import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P17-E1",
    audio: "Audios/06-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page17/Key/E1answerKey.png",
    // textAlign: "center",
    stylesTextInput: {
      width: 200,
      paddingLeft: 0,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write st or ts. <span style='color: white'><headphone name='&nbsp 06' typeimg=sound src='Audios/06-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px'>
            <span><b>1. </b>ca<u style='color:gray; text-underline-offset: 5px'>ts&nbsp</u></span> 
            <span><b>2. </b>ve#</span> 
            <span><b>3. </b>la#</span> 
            <span><b>4. </b>ha#</span> 
            <span><b>5. </b>ca#</span> 
            <span><b>6. </b>ve#</span> 
          </div>
        `,
        answer: ["st", "st", "ts", "st", "ts"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P17-E2",
    audio: "Audios/07-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page17/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 100,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Write the words. Then listen and check. <span style='color: white'><headphone name='&nbsp 06' typeimg=sound src='Audios/06-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#1eb26a",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>cats</span>",
          "cast",
          "last",
          "pets",
          "vets",
        ],
        width: "max-content",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width: 23cm; margin-top: 20px; display: flex; gap: 80px; align-items: center'>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <span>It's time for the <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspcats&nbsp</u>. </span>
            <span>To visit the <sup>2</sup>#. </span>
            <span>They fell from a tree.</span>
            <span>They're very sad <sup>3</sup>#. </span>
            <span>The vets are kind.</span>
            <span>The cats get a <sup>4</sup>#.</span>
            <span>They are happy.</span>
            <span>To go home at <sup>5</sup>#.</span>
          </div>
          <div style='margin: auto 0 auto'><img src='img/FriendsPlus/Page17/E2/1.jpg' style='width: 12cm'/></div>
        </div>
        `,
        answer: ["vets", "pets", "cast", "last"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P17-E3",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page17/Key/E3answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Circle the correct word.",
        color: "#2d408e",
        left: 60,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontWeight: "bold",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: ["cats / cast", "cats / cast", "vets / vest", "vets / vest"],
        answers: ["0-0", "2-0", "1-2", "3-2"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap:20px; margin-top: 10px'>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page17/E3/1.jpg'/>
          <span style='padding-left: 10px'><input id=0 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page17/E3/2.jpg'/>
          <span style='padding-left: 10px'><input id=1 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page17/E3/3.jpg'/>
          <span style='padding-left: 10px'><input id=2 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page17/E3/4.jpg'/>
          <span style='padding-left: 10px'><input id=3 type='Circle'/></span>
        </div>
      </div>
      `,
    },
  },
};

export default json;
