import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P92-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page92/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Complete the sentences. (✗ = no, ✓ = yes)",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 150,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap: 7px'>
          <span><b>1. </b>There were sandwiches at the party. They <u style='color: gray; text-underline-offset: 5px'>&nbspweren't&nbsp</u> very big. <b>(✗)</b></span>
          <span><b>2. </b>He # hungry yesterday. <b>(✓)</b></span>
          <span><b>3. </b>We # at school on Friday. <b>(✗)</b></span>
          <span><b>4. </b>There # dinosaur bones at the museum. <b>(✓)</b></span>
          <span><b>5. </b>Yesterday it # windy, but it was rainy. <b>(✗)</b></span>
          <span><b>6. </b># Max and Holly late yesterday?</span>
        </div>
        `,
        answer: ["was", "weren't", "were", "wasn't", "Were"],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P92-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page92/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the sentences so that they are true for you.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 180,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <span>Yesterday ...</span>
          <div style='width: 27cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 20px'>
            <span><b>1. </b>I # a lot of fun.</span>
            <span><b>2. </b>I # pizza for lunch.</span>
            <span><b>3. </b>I # eggs for breakfast.</span>
            <span><b>4. </b>I # dinner with my family.</span>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
