import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P14-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page14/Key/E1answerKey.png",
    stylesTextInput: {
      width: 300,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Look and read. Write the correct words on the line.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#1eb26a",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "café",
          "sports center",
          "<span class='strikediag'>movie theater</span>",
          "market",
          "playground",
          "shopping mall",
          "skatepark",
          "swimming pool",
        ],
        width: "20cm",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src='img/FriendsPlus/Page14/E1/1.jpg' style='width: 100%; padding: 20px 0'/>
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>People sit in here and watch movies. <u style='color:gray; text-underline-offset: 5px'>&nbspmovie theater&nbsp</u></span>
          <span><b>2. </b>You can buy clothes, books, and toys here. #</span>
          <span><b>3. </b>You can play outside on swings and a slide. #</span>
          <span><b>4. </b>You can play basketball here. #</span>
          <span><b>5. </b>You can drink juice here. You can also eat cake. #</span>
          <span><b>6. </b>People come here to swim and play in the water. #</span>
          <span><b>7. </b>You can use your skateboard or scooter here. #</span>
          <span><b>8. </b>You can buy fish, meat, and vegetables here.#</span>
        </div>
        `,
        answer: [
          "shopping mall",
          "playground",
          "sports center",
          "café",
          "swimming pool",
          "skatepark",
          "market",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P14-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    stylesTextInput: {
      width: 700,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Where are the people? Look and write.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page14/E2/1.jpg' style='width: 100%; padding: 20px 0'/>
        <div style='width: 25cm; margin-left: 30px; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>&nbsp<u style='color:gray; text-underline-offset: 5px'>&nbspThey're at the playground&nbsp</u></span>
          <span><b>2. </b> #</span>
          <span><b>3. </b> #</span>
          <span><b>4. </b> #</span>
        </div>
        `,
        answer: [
          "They're at the swimming pool.",
          "They're at the café.",
          "They're at the skatepark.",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P14-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 35,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and write T true or F false. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex; line-height:56px'>
          <div>
            <b>1.</b> Mai’s parents are pilots. #<br>
            <b>2.</b> Her mother is an office worker. #
          </div>
          <div style='margin-left:50px'>
            <b>3.</b> Her grandpa is a pilot. #<br>
            <b>4.</b> Mai and Minh study at school. #
          </div>
          
        </div>
        
        `,
        answer: ["F", "T", "F", "T"],
      },
    ],
  },
};

export default json;
