import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P80-E1",
    exerciseKey: "img/FriendsPlus/Page80/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: 170,
    maxLength: 20,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write the words.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   marginLeft: "10px",
    //   styleElementHintBox: {
    //     margin: "0px 15px",
    //     fontWeight: "normal",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "build",
    //       "<span class='strikediag'>crowded</span>",
    //       "playgrounds",
    //       "robots",
    //       "spaceships",
    //       "travel",
    //     ],
    //     width: "12cm",
    //     inline: true,
    //   },
    // ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 26cm; display: flex; gap: 20px'>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <span><b>1. </b>When you turn on the TV, you can see lots of different <u style='color:gray; text-underline-offset: 5px'>&nbspshows&nbsp</u>.</span>
            <span><b>2. </b>I like reading books on my #. </span>
            <span><b>3. </b>Lots of children like playing # games.</span>
            <span><b>4. </b>Someone who sings in front of an audience is a #.</span>
            <span><b>5. </b>An actor in movies is a movie #. </span>
            <span><b>6. </b>You can use your # when you are out.</span>
            <span><b>7. </b>To change the channel on the TV, you can use a remote #.</span>
            <span><b>8. </b>You can watch a movie on a #.</span>
          </div>
          <img src='img/FriendsPlus/Page80/E1/1.jpg' style='height: 10cm'>
          
        </div>
        `,
        answer: [
          "tablet",
          "video",
          "singer",
          "star",
          "smartphone",
          "control",
          "DVD",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P80-E2",
    exerciseKey: "img/FriendsPlus/Page80/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 170,
    maxLength: 20,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write. Use the words above.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   marginLeft: "10px",
    //   styleElementHintBox: {
    //     margin: "0px 15px",
    //     fontWeight: "normal",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "build",
    //       "<span class='strikediag'>crowded</span>",
    //       "playgrounds",
    //       "robots",
    //       "spaceships",
    //       "travel",
    //     ],
    //     width: "12cm",
    //     inline: true,
    //   },
    // ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 26cm; display: flex; gap: 20px'>
          <span style='line-height: 1.75em'>
            Yesterday, we watched some different TV <span style='text-wrap: nowrap'><sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspshows&nbsp</u></span>. First my sister and I watched a movie with our favorite movie <span style='text-wrap: nowrap'><sup>2</sup>#</span>. Then Mom picked up the remote <span style='text-wrap: nowrap'><sup>3</sup>#</span> and she watched her favorite <span style='text-wrap: nowrap'><sup>4</sup>#</span>. I didn’t like the music, so I read a book on my <span style='text-wrap: nowrap'><sup>5</sup>#</span>. My dad was out, but he phoned from his <span style='text-wrap: nowrap'><sup>6</sup>#</span> to say hi. When Dad got home, he played a video <span style='text-wrap: nowrap'><sup>7</sup>#</span>. After dinner we all watched a <span style='text-wrap: nowrap'><sup>8</sup>#</span> of our family vacation.
          </span>
          <img src='img/FriendsPlus/Page80/E2/1.jpg' style='height: 12cm'>
          
        </div>
        `,
        answer: [
          "star",
          "control",
          "singer",
          "tablet ",
          "smartphone",
          "game",
          "DVD",
        ],
      },
    ],
  },
};

export default json;
