import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P56-E1",
    audio: "Audios/30-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page76/Key/E1answerKey.png",
    // stylesTextInput: {
    //   width: 300,
    // },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and draw lines from the person to the correct picture. <span style='color: white'><headphone name='&nbsp 30' typeimg=sound src='Audios/30-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        // lineDirection: "row",
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: `
              <img src='img/FriendsPlus/Page76/E1/1.jpg' width='130px'/>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 0
          {
            children: `
              <img src='img/FriendsPlus/Page76/E1/2.jpg' width='130px'/>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 1
          {
            children: `
              <img src='img/FriendsPlus/Page76/E1/3.jpg' width='130px'/>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 2
          {
            children: `
              <div style='padding: 2px 30px; border: 1px solid rgb(116, 119, 176); border-radius: 5px; text-align: center'>Thanh</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 3
          {
            children: `
              <div style='padding: 2px 30px; border: 1px solid rgb(116, 119, 176); border-radius: 5px; text-align: center'>Anh</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 4
          {
            children: `
              <div style='padding: 2px 30px; border: 1px solid rgb(116, 119, 176); border-radius: 5px; text-align: center'>Vinh</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 5
          {
            children: `
              <div style='padding: 2px 30px; border: 1px solid rgb(116, 119, 176); border-radius: 5px; text-align: center'>Huy</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 6
          {
            children: `
              <div style='padding: 2px 30px; border: 1px solid rgb(116, 119, 176); border-radius: 5px; text-align: center'>Mai</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 7
          {
            children: `
              <img src='img/FriendsPlus/Page76/E1/4.jpg' width='130px'/>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 8
          {
            children: `
              <img src='img/FriendsPlus/Page76/E1/5.jpg' width='130px'/>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 9
          {
            children: `
              <img src='img/FriendsPlus/Page76/E1/6.jpg' width='130px'/>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 10
        ],
        answers: ["0-4", "5-10", "6-8", "1-7"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; gap: 200px; align-items: center'>
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />
          <img src='img/FriendsPlus/Page76/E1/3.jpg' width='130px'/>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <div style='padding: 2px 30px; border: 1px solid rgb(116, 119, 176); border-radius: 5px; text-align: center'>Thanh</div>
          <input id='4' type='boxMatch' />
          <input id='5' type='boxMatch' />
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
        </div>
        
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <input id='8' type='boxMatch' />
          <input id='9' type='boxMatch' />
          <input id='10' type='boxMatch' />
        </div>
      </div>
      <div style="border-top: 2px solid rgb(64, 199, 244); position: absolute; top: 371.211px; left: 130px; width: 328.588px; z-index: 1; transform: rotate(-52.507deg); transform-origin: 0px 0px;"></div>
      `,
    },
  },
  2: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P76-E2",
    exerciseKey: "img/FriendsPlus/Page75/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 80,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write. Where will you be in the future?",
        color: "#203c8f",
        left: 55,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>(This weekend) <input placeholder='This weekend, I will be ' width='736px'/></span>
          <span><b>2. </b>(In four years) <input width='748px'/></span>
          <span><b>3. </b>(Tomorrow) <input width='772px'/></span>
          <span><b>4. </b>(Next vacation) <input width='736px'/></span>
        </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P76-E3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E3answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 80,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Read the song in the Student Book. Write We'll or We won't.",
        color: "#203c8f",
        left: 55,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 27cm; display: flex; gap: 20px; align-items: center'>
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b> <u style='color:gray; text-underline-offset: 5px'>&nbspWe'll&nbsp</u> go on a trip to the moon. </span>
            <span><b>2. </b> # fly in a spaceship. </span>
            <span><b>3. </b> # look at the stars.</span>
            <span><b>4. </b> # eat sandwiches.</span>
            <span><b>5. </b> # come back to Earth tomorrow. </span>
            <span><b>6. </b> # come back to Earth next week. </span>
          </div>
          <img src='img/FriendsPlus/Page76/E3/1.jpg' style='width: 14cm'/>
        </div>
        
        `,
        answer: ["We'll", "We'll", "We won't", "We'll", "We won't"],
      },
    ],
  },
};

export default json;
