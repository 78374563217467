import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P61-E1",
    exerciseKey: "img/FriendsPlus/Page61/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddingInline: 5,
    //   paddingBlock: "0px",
    //   borderColor: "#ee1d23",
    //   color: "#57585a",
    //   styleElementHintBox: {
    //     margin: "0px 10px",
    //     padding: "2px 4px",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "litter",
    //       "path",
    //       "fountain",
    //       "garbage can",
    //       "bushes",
    //       "tree",
    //       "<span class='strikediag'>park</span>",
    //     ],
    //     width: 500,
    //     inline: true,
    //   },
    // ],
    component: T6,
    questions: [
      {
        title: `
        <div style='display: flex; gap: 30px; width: 25cm'>
          <div style='display: flex; flex-direction: column; gap: 15px'>
            <span><b>1. </b>There’s the bus! Walk <u style='color:gray; text-underline-offset: 5px'>&nbspquickly&nbsp</u>, Minh! (quick)</span>
            <span><b>2. </b>I always do my homework #. (neat)</span>
            <span><b>3. </b>We talk # in the library. (quiet)</span>
            <span><b>4. </b>I always speak # to my teacher. (polite)</span>
            <span><b>5. </b>Max can run #. (fast)</span>
          </div>
          <img src='img/FriendsPlus/Page61/E1/1.jpg' style='height: 6cm' />
        </div>
        `,
        answer: ["neatly", "quietly", "politely", "fast"],
      },
    ],
  },
  2: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P61-E2",
    exerciseKey: "img/FriendsPlus/Page61/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Look at the pictures. Write.",
        color: "#203c8f",
        left: 55,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#ee1d23",
      color: "#57585a",
      styleElementHintBox: {
        margin: "0px 20px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>careful</span>",
          "good",
          "quiet",
          "polite",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 140px; width: 25cm'>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <img src='img/FriendsPlus/Page61/E2/1.jpg' style='width: 100%' />
            <span>You must swim <u style='color:gray; text-underline-offset: 5px'>&nbspcarefully&nbsp</u>.</span>
          </div>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <img src='img/FriendsPlus/Page61/E2/2.jpg' style='width: 100%' />
            <span>You must play music #.<br>Grandma is sleeping.</span>
          </div>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <img src='img/FriendsPlus/Page61/E2/3.jpg' style='width: 100%' />
            <span>You must do your homework #.</span>
          </div>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <img src='img/FriendsPlus/Page61/E2/4.jpg' style='width: 100%' />
            <span>You must walk # on the path. You mustn’t pick the flowers.</span>
          </div>
        </div>
        `,
        answer: ["quietly", "well", "politely"],
      },
    ],
  },
};

export default json;
