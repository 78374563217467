import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P19-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page19/Key/E1answerKey.png",
    stylesTextInput: {
      width: 150,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='width: 25cm;display: inline-block;'>Write always, usually, sometimes, or never.</span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px'>
          <span><b>1. </b>We <u style='color:gray; text-underline-offset: 5px'>&nbspusually&nbsp</u> watch TV after school.</span>
          <span><b>2. </b>My dad # takes us to a concert. We go on our birthday or at Tet.</span>
          <span><b>3. </b>I # watch adventure movies. I love them!</span>
          <span><b>4. </b>Andy # plays with Woody.</span>
        </div>
        `,
        answer: ["sometimes", "always", "never"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P19-E2",
    audio: "",
    video: "",
    stylesTextInput: { background: "none", border: "none" },
    textareaStyle: {
      marginLeft: 7,
      background: "none",
      width: "calc(100% - 70px)",
      resize: "none",
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    // textAlign: "center",
    inputSize: 500,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233)'>
            <div style='text-align: center; border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px'>
              <span style='color: rgb(0,136,202); font-weight: bold; font-size: 25px'>Notes</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Favorite movie:</span>
              <input placeholder='...'>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Main characters: </span>
              <input placeholder='...'>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What happens in the movie? </span>
              <div style='position: absolute;top: 0;left: 37.5%; width: 60%'><textarea id='3' rows=3 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What do you like about the movie?</span>
              <div style='position: absolute;top: 0;left: 37.5%; width: 60%'><textarea id='5' rows=3 placeholder='...'></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
          </div>
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P19-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "3",
        title:
          "Write a review of your favorite movie. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='border: 3px solid rgb(101, 193, 139); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=6 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: ["My favorite movie"],
      },
    ],
  },
};

export default json;
