import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P65-E1",
    // audio: "Audios/07-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      width: 350,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Complete the story with the sentences.",
        color: "#2d408e",
        left: 50,
      },
    ],
    // maxLength: 1,
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 26cm; display: flex; gap: 20px;'>
          <div style='display: flex; gap: 5px; flex-direction: column; width: 70%; line-height: 1.8em'>
            <span><b>1. </b>"You mustn't scream, Grandma. He's my friend."</span>
            <span><b>2. </b>They lived happily ever after.</span>
            <span><b>3. </b>It walked quietly behind her.</span>
            <span><b>4. </b>Once upon a time, Red Riding Hood went to visit Grandma.</span>
          </div>
          <div style='position: relative;'>
            <img src='img/FriendsPlus/Page65/E1/1.jpg' style='width: 100%' />
            <span style='position: absolute; top: 45px; left: 60px; width: 82%'><sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspOnce upon a time, Red Riding Hood went to visit Grandma.&nbsp</u> She walked slowly along the path in the forest. A wolf saw her. <span style='text-wrap: nowrap'><sup>2</sup>#</span> Grandma was happy to see Red Riding Hood, but she screamed loudly when she saw the wolf. Red Riding Hood said, <span style='text-wrap: nowrap'><sup>3</sup><input width='550px'/></span> So Grandma made cookies and they ate them in the sun. <span style='text-wrap: nowrap'><sup>4</sup>#</span>            </span>
          </div>
        </div>
        `,
        answer: [
          "It walked quietly behind her",
          `"You mustn't scream, Grandma. He's my friend."`,
          "They lived happily ever after",
        ],
        initialValue: [],
      },
    ],
  },

  2: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P65-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page59/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      fontSize: 23,
      width: 600,
    },
    textareaStyle: {
      // marginLeft: 7,
      background: "none",
      width: "100%",
      resize: "none",
      fontSize: 23,
      lineHeight: "2em",
      padding: 0,
      // border: "1px solid rgb(168, 168, 168)",
    },
    maxLength: 167,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 22px; box-shadow: 5px 5px rgb(233, 233, 233);padding: 20px'>
            <table style='width: 100%; table-layout: auto; border-collapse: collapse'>
              <tr style='background: rgb(215, 230, 245); color: rgb(0, 136, 202); '>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>Characters</th>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>Beginning</th>
              </tr>
              <tr>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'><textarea id=0 rows=2 placeholder='...'></textarea></td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'><textarea id=1 rows=2 placeholder='...'></textarea></td>
              </tr>
              <tr style='background: rgb(215, 230, 245); color: rgb(0, 136, 202); '>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>Middle</th>
                <th style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'>End</th>
              </tr>
              <tr>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'><textarea id=2 rows=2 placeholder='...'></textarea></td>
                <td style='padding: 0 10px; border: 1px solid rgb(0, 136, 202)'><textarea id=3 rows=2 placeholder='...'></textarea></td>
              </tr>
            </table>
          </div>
        `,
        InputRong: true,
        answer: [
          "Grandma, wolf, Red Riding Hood",
          "Red Riding Hood went to visit her Grandma.",
          "Red Riding Hood and the wolf met Grandma, who was scared of the wolf.",
          "Grandma made cookies and they lived happily ever after.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P65-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write a story. Use the notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(244, 132, 101); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=4 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
