import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Review 2",
    id: "WB5-2024-R2-P46-E1",
    audio: "Audios/18-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page46/Key/E1answerKey.png",
    inputSize: 200,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // height: 15,
      // padding: "10",
    },

    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='z-index: 1'>Listen and write. <span style='color: white'><headphone name='&nbsp 18' typeimg=sound src='Audios/18-tieude.mp3'></headphone></span></span>",
        color: "#2d408e",
        left: 80,
      },
    ],
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      marginTop: 20,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "Australia",
          "France",
          "Thailand",
          "<span class='strikediag'>Singapore</span>",
          "the U.S.A.",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <img style='width:27cm; margin-top: -85px' src='img/FriendsPlus/Page46/E1/1.jpg'/>
          <hintbox id=0></hintbox>
          <div style='width: 25cm; display: flex; flex-direction: column; margin-top: 20px'>
            <span><b>1. </b>The man with the bags was in <u style='color:gray; text-underline-offset: 5px'>&nbspSingapore&nbsp</u>.</span>
            <span><b>2. </b>The girl wasn’t in #. She was in #.</span>
            <span><b>3. </b>Tuan wants to go to #.</span>
            <span><b>4. </b>Aunt Linh was in #.</span>
          </div>

         `,
        answer: ["Australia", "Thailand", "the U.S.A", "France"],
      },
    ],
  },
  2: {
    unit: "Review 2",
    id: "WB5-2024-R2-P46-E2",
    // audio: "Audios/18-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page46/Key/E2answerKey.png",
    inputSize: 100,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // height: 15,
      // padding: "10",
    },

    titleQuestion: [
      {
        num: "2",
        title: "Read and write T (true) or F (false).",
        color: "#2d408e",
        left: 85,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <img style='width:25cm' src='img/FriendsPlus/Page46/E2/1.jpg'/>
          <div style='width: 25cm; display: flex; flex-direction: column; margin-top: 20px'>
            <span><b>1. </b>Mai was on vacation in Cambodia. <u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspF&nbsp&nbsp</u></span>
            <span><b>2. </b>There were museums in Thailand. #</span>
            <span><b>3. </b>Mai visited everywhere on foot. #</span>
            <span><b>4. </b>The weather wasn’t sunny. #</span>
            <span><b>5. </b>Mai enjoyed her vacation. #</span>
          </div>

         `,
        answer: ["T", "F", "T", "T"],
      },
    ],
  },
};

export default json;
