import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P70-E1",
    exerciseKey: "img/FriendsPlus/Page70/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: "23cm",
    maxLength: 80,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write about what you should and shouldn’t do.",
        color: "#203c8f",
        left: 55,
        top: 100,
      },
    ],
    notification: true,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#253f8e",
      color: "#57585a",
      styleElementHintBox: {
        margin: "0px 20px",
      },
    },
    hintBox: [
      {
        src: [
          "go to bed",
          "drink water",
          "play outside",
          "go to the doctor",
          "eat some fruit",
          "rest",
          "go to school",
          "eat candy",
        ],
        width: 550,
        // inline: true,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    noInputMargin: true,
    stylesTextInput: {
      borderColor: "gray",
    },
    questions: [
      {
        title: `
        <div style='width: 25cm; position: relative; margin-bottom: 30px'>
          <hintbox id=0></hintbox>
          <img src='img/FriendsPlus/Page70/E1/1.jpg' style='width: 7cm; position: absolute; top: -70px; right: 80px' />
        </div>
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px'>
          <span><b>1. </b>What should I do when I have a headache?</span>
          <span><u style='color:gray; text-underline-offset: 5px'>&nbspYou should</u><input width='735px'/></span>
          <span><u style='color:gray; text-underline-offset: 5px'>&nbspYou shouldn't</u><input  width='708px'/></span>
          <span><b>2. </b>What should I do when I have a cough and a cold?</span>
          #
          #
          <span><b>3. </b>What should I do when I have a stomachache?</span>
          #
          #
        </div>
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P70-E2",
    exerciseKey: "img/FriendsPlus/Page70/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 390,
    maxLength: 80,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Read the song in the Student Book. Complete the table.",
        color: "#203c8f",
        left: 55,
        top: 100,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    stylesTextInput: {
      paddingLeft: 0,
    },
    questions: [
      {
        title: `
        <div style='display: flex; gap: 40px; align-items: center'>
            <div style='width: 15cm; border: 1px solid rgb(0, 136, 202); border-radius: 5px; overflow: hidden'>
              <div style='background: rgb(215, 230, 245); font-weight: bold; padding: 5px 20px; border-bottom: 1px solid rgb(0, 136, 202)'>
                Things Bobby should do
              </div>
                <div style='padding: 10px 20px; display: flex; flex-direction: column; gap: 5px'>
                  <span>He should <u style='color:gray; text-underline-offset: 5px'>&nbspdrink some water.&nbsp</u></span>
                  <span>He should #</span>
                  <input width='510px'/>
                  <span>He should #</span>
                </div>
              <div style='background: rgb(215, 230, 245); font-weight: bold; padding: 5px 20px; border-bottom: 1px solid rgb(0, 136, 202); border-top: 1px solid rgb(0, 136, 202)'>
                Things Bobby shouldn’t do
              </div>
                <div style='padding: 10px 20px; display: flex; flex-direction: column; gap: 5px'>
                  <span>He shouldn't <input width='360px'/></span>
                  <input width='510px'/>
                  <span>He shouldn't <input width='360px'/></span>
                </div>
            </div>
            <img src='img/FriendsPlus/Page70/E2/1.jpg' style='height: 10cm' />
        </div>
        `,
        answer: [
          "go to bed.",
          "He should close his eyes.",
          "rest his head.",
          "play video games",
          "He shouldn't watch TV",
          "eat any more today",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P70-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 50,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write T (true) or F (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page70/E1/1.jpg'/>
        <div  >
          <b>1</b>&ensp;Linh gets up at six o'clock in the morning. <u style='color:rgb(25 181 239)' >&ensp;T&ensp;</u><br>
          <b>2</b>&ensp;She walks to school at seven o’clock in the morning. #<br>
          <b>3</b>&ensp;Linh’s mom goes to work at six forty-five in the morning. #<br>
          <b>4</b>&ensp;Linh’s mom goes home at four thirty in the afternoon. #<br>
          <b>5</b>&ensp;Linh and her mom have dinner together. #

        </div>
        `,
        answer: ["T", "T", "F", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P70-E4",
    audio: "",
    video: "",

    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What’ your favorite room in your school? Why?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P70-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page70/E5/1.jpg" }]],
  },
};

export default json;
