import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P42-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    stylesTextInput: {
      width: 300,
      // paddingLeft: 0,
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Write answers that are true for you. Write Yes, I did or No, I didn’t.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Did you play soccer on Saturday morning? #</span>
          <span><b>2. </b>Did you listen to music on Sunday? #</span>
          <span><b>3. </b>Did you watch TV on the weekend? #</span>
          <span><b>4. </b>Did you play the guitar on the weekend? #</span>
          <span><b>5. </b>Did you cook on Friday evening? #</span>
          <span><b>6. </b>Did you help your mom yesterday?#</span>
        </div>
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P42-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page42/Key/E2answerKey.png",
    stylesTextInput: {
      width: 600,
      // paddingLeft: 0,
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write things you did and didn’t do on the weekend.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>I # on the weekend.</span>
          <span><b>2. </b>I # on the weekend.</span>
          <span><b>3. </b>I didn’t <input width='532px'> on the weekend.</span>
          <span><b>4. </b>I didn’t <input width='532px'> on the weekend.</span>
        </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    id: "WB5-2024-U6-P42-E3",
    audio: "Audios/16-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page42/Key/E3answerKey.png",
    stylesTextInput: {
      width: 150,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen to the song. Complete the sentences. <span style='color: white'><headphone name='&nbsp 16' typeimg=sound src='Audios/16-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; padding-left: 3cm'><b style='font-size: 27px; color: rgb(246, 140, 50); -webkit-text-stroke: 2px rgb(230, 231, 233); paint-order: stroke fill'>My birthday party</b></div>
        <div style='position: relative'>
          <div style='width: 25cm; margin-top: 10px; padding-right: 5cm; display: flex; flex-direction: column; gap: 5px; position: relative; z-index: 1'>
            <span>I <sup>1</sup># my birthday party.</span>
            <span>I <sup>2</sup># lots of friends.</span>
            <span>We <sup>3</sup># and <sup>4</sup># all day long.</span>
            <span>I didn’t <sup>5</sup># my party to end.</span>
            <span>It <sup>6</sup># in the morning.</span>
            <span>We <sup>7</sup># a game outside. </span>
            <span>Then we <sup>8</sup># to music,</span>
            <span>And <sup>9</sup># until lunchtime.</span>
            <span>Did you <sup>10</sup>#cake and ice cream?</span>
            <span>Yes, Mom <sup>11</sup>#a chocolate cake.</span>
            <span>Did you <sup>12</sup>#some nice presents?</span>
            <span>Yes, it <sup>13</sup>#a wonderful day.</span>
          </div>
          <img src='img/FriendsPlus/Page42/E3/1.jpg' style='position: absolute; top: 30px; right: 1cm; width: 10cm'>
        </div>
        `,
        answer: [
          "loved",
          "invited",
          "played",
          "smiled",
          "want",
          "started",
          "played",
          "listened",
          "danced",
          "have",
          "cooked",
          "get",
          "was",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P42-E4",
    audio: "",
    video: "",

    exerciseKey: "",
    component: T6,
    maxLength: 7,
    inputSize: 40,
    hideBtnFooter: true,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "Where do you go on vacation? Why do you like it?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P42-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page42/E5/1.jpg" }]],
  },
};

export default json;
