import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P32-E1",
    // audio: "Audios/11-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page32/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the conversation between Trang and Duy. Choose the best answer",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "1px solid blue",
          padding: "0 5px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          borderColor: "transparent",
        },
        selectWordStyle: {
          borderColor: "blue",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: ["(A) (B) (C)", "(A) (B) (C)", "(A) (B) (C)", "(A) (B) (C)"],
        answers: ["0-2", "2-0", "1-1", "3-1"],
        initialValue: ["0-2"],
      },
      Layout: `
      <div style='font-size:22px'>
        <div style='text-align: center; width: 27cm'><img style='width: 22cm' src='img/FriendsPlus/Page32/E1/1.jpg'/></div>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 30px; margin-top: 20px'>
          <div>
            <span><b>1. </b> Hi, Duy. Where are you?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=0 type='Circle'/></div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
              <span>I’m in the middle of the trees.</span>
              <span>I’m inside the play house.</span>
              <span>I’m going by bike to the park.</span>
            </div>
            </div>
          </div>
          <div>
            <span><b>3. </b>Can you see the play house?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=1 type='Circle'/></div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
              <span>Yes, it’s in the middle of the park.</span>
              <span>Yes, Thu is playing inside of it.</span>
              <span>Yes, Trang is inside it.</span>
            </div>
            </div>
          </div>
          <div>
            <span><b>2. </b>Is Tam there?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=2 type='Circle'/></div>
              <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                <span>Yes, she’s in the middle of the park.</span>
                <span>No, she’s not.</span>
                <span>Yes, she’s inside the play house.</span>
              </div>
            </div>
          </div>
          <div>
            <span><b>4. </b>I can see Thu, too!</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=3 type='Circle'/></div>
                <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                  <span>Yes, she’s in the middle of the gate.</span>
                  <span>Yes, she’s inside the play house.</span>
                  <span>Yes, she often comes by bike to the park.</span>
                </div>
              </div>
            </div>
          </div>
          
      </div>
      
      `,
    },
  },
  2: {
    id: "WB5-2024-U4-P32-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page32/Key/E2answerKey.png",
    stylesTextInput: {
      width: 50,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text in the Student Book. Write T (true) or F (false).",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
            <div style='width: 25cm; display: grid; grid-template-columns: 3fr 1fr; grid-gap: 10px 30px'>
              <span><b>1. </b>Ben Thanh market is in the middle of Nha Trang.</span><span><u style='color:gray; text-underline-offset: 5px; padding-left: 5px'>&nbsp&nbsp&nbspF&nbsp&nbsp&nbsp</u></span>
              <span><b>2. </b>In 1912, it was easy to go there on foot. </span><span>#</span>
              <span><b>3. </b>Now, people usually go there by bike. </span><span>#</span>
              <span><b>4. </b>There is a lot of traffic near the market. </span><span>#</span>
              <span><b>5. </b>Inside the market you can buy bikes. </span><span>#</span>
              <span><b>6. </b>Visitors love eating lunch at the market.</span><span>#</span>
            </div>
        `,
        answer: ["T", "F", "T", "F", "F"],
      },
    ],
  },
};

export default json;
