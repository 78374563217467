import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P59-E1",
    exerciseKey: "img/FriendsPlus/Page59/Key/E1answerKey.png",
    // audio: "Audios/24-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write <i>and</i> or <i>but</i>.",
        color: "#203c8f",
        left: 50,
      },
    ],
    component: T6,
    stylesTextInput: {
      width: 100,
      background: "none",
    },
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b>Wat Chedi Luang is very old, <u style='color:gray; text-underline-offset: 5px'>&nbspbut&nbsp</u> Wat Chiang Man is older.</span>
            <span><b>2. </b>The Son Doong cave is about 9 kilometers long # 160 meters wide.</span>
            <span><b>3. </b>Keangnam Tower is 349 meters tall, # Landmark 81 is taller.</span>
            <span><b>4. </b>The Ping River is very long # wide.</span>
          </div>
        `,
        answer: ["and", "but", "and"],
      },
    ],
  },

  2: {
    unit: "Unit 8",
    id: "WB5-2024-U7-P59-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page59/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      fontSize: 23,
      width: 600,
    },
    textareaStyle: {
      // marginLeft: 7,
      background: "none",
      width: "100%",
      resize: "none",
      fontSize: 23,
      lineHeight: "2em",
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    maxLength: 167,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 22px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 25px'>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What’s the place?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Where is it?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What can you find there?</span>
              <div style='position: absolute;top: 0;left: 33.3%; width: 65%; height: 90px; overflow: hidden; z-index: 1'><textarea id=2 rows=2 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>How old is it?</span>
              <div style='position: absolute;top: 0;left: 33.3%; width: 65%; height: 90px; overflow: hidden; z-index: 1'><textarea id=3 rows=2 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>How tall / long / wide is it?</span>
              <div style='position: absolute;top: 0;left: 33.3%; width: 65%; height: 180px; overflow: hidden; z-index: 1'><textarea id=4 rows=4 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write a fact file. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(244, 132, 101); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=6 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
