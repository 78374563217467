import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P13-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page13/Key/E1answerKey.png",
    stylesTextInput: {
      width: 500,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='width: 25cm;display: inline-block;'>Rewrite the sentences. Use capital letters and punctuation marks. Remember the proper nouns.</span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: grid; grid-template-columns: 4fr 6fr; grid-gap: 10px'>
          <span><b>1. </b>im ly</span><span><u style='color:gray; text-underline-offset: 5px'>&nbspI'm Ly.&nbsp</u></span>
          <span><b>2. </b>i live in viet nam</span><span>#</span>
          <span><b>3. </b>my school is in ho chi minh city</span><span>#</span>
          <span><b>4. </b>my teacher is mr quang</span><span>#</span>
        </div>
        `,
        answer: [
          "I live in Viet Nam.",
          "My school is in Ho Chi Minh City.",
          "My teacher is Mr Quang.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P13-E2",
    audio: "",
    video: "",
    stylesTextInput: { background: "none", border: "none" },
    textareaStyle: {
      marginLeft: 7,
      background: "none",
      width: "calc(100% - 70px)",
      resize: "none",
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    // textAlign: "center",
    inputSize: 500,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233)'>
            <div style='text-align: center; border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px'>
              <span style='color: rgb(0,136,202); font-weight: bold; font-size: 25px'>Notes</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Name</span>
              <input placeholder='...'>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Where I live: </span>
              <input placeholder='...'>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Name of school, address: </span>
              <div style='position: absolute;top: 0;left: 37.5%; width: 60%'><textarea id='3' rows=2 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>How to get to school: </span>
              <input placeholder='...'>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What do you do after school?</span>
              <div style='position: absolute;top: 0;left: 37.5%; width: 60%'><textarea id='5' rows=2' placeholder='...'></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What do you often do on Saturdays?</span>
              <div style='position: absolute;top: 0;left: 37.5%; width: 60%'><textarea id='6' rows=2 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
          </div>
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P13-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "3",
        title:
          "Write a description of your school day. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='border: 3px solid rgb(101, 193, 139); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=6 ></textarea>
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
