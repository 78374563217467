import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P58-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/Key/E1answerKey.png",
    stylesTextInput: {
      width: 220,
      background: "none",
      borderBottom: "1px solid gray",
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Order the letters and write the word.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='width: 26cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 50px'>
            <div>
              <img src='img/FriendsPlus/Page58/E1/1.jpg' style='height: 5cm'/>
            </div>
            <div style='position: relative'>
              <img src='img/FriendsPlus/Page58/E1/2.jpg' style='height: 5cm'/>
              <div style='position: absolute; bottom: 0; right: 0'>#</div>
            </div>
            <div style='position: relative'>
              <img src='img/FriendsPlus/Page58/E1/3.jpg' style='height: 5cm'/>
              <div style='position: absolute; bottom: 0; right: 0'>#</div>
            </div>
            <div style='position: relative'>
              <img src='img/FriendsPlus/Page58/E1/4.jpg' style='height: 5cm'/>
              <div style='position: absolute; bottom: 0; right: 0'>#</div>
            </div>
            <div style='position: relative'>
              <img src='img/FriendsPlus/Page58/E1/5.jpg' style='height: 5cm'/>
              <div style='position: absolute; bottom: 0; right: 0'>#</div>
            </div>
          </div>
        `,
        answer: ["restaurant", "floor", "dark", "building"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P58-E2",
    exerciseKey: "img/FriendsPlus/Page58/Key/E2answerKey.png",
    // audio: "Audios/24-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 100,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Read the text in the Student Book. Write sentences.",
        color: "#203c8f",
        left: 50,
      },
    ],
    component: T6,
    stylesTextInput: {
      width: "24cm",
      background: "none",
      marginLeft: 20,
    },
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b>Fansipan mountain / high / Bach Ma mountain</span>
            <u style='margin-left: 25px; color:gray; text-underline-offset: 5px'>&nbspFansipan mountain is higher than Bach Ma mountain.&nbsp</u>
            <span><b>2. </b>Son Doong cave / large / other caves in the world</span>
            #
            <span><b>3. </b>Dong Nai river / long / other rivers in Viet Nam</span>
            #
            <span><b>4. </b>Landmark 81 / tall / Keangnam Tower</span>
            #
          </div>
        `,
        answer: [
          "Son Doong cave is larger than other caves in the world.",
          "Dong Nai river is longer than other rivers in Viet Nam.",
          "Landmark 81 is taller than Keangnam Tower.",
        ],
      },
    ],
  },
};

export default json;
