import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P68-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page68/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match the sentences with the pictures.",
        color: "#203c8f",
        left: 99,
      },
    ],
    textAlign: "center",
    maxLength: 1,

    question: {
      DrawLines: {
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 26,
          maxLength: 1,
          border: "none",
        },
        answers: ["a", "d", "f", "e", "g", "b", "h"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "school_trip / boat_trip.",
          "movie_theater / museum.",
          "train / bus.",
          "four / five.",
          " postcard / dinosaur_model.",
        ],
        answers: ["1-4", "3-4", "2-0", "0-0", "4-6"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='position:relative'>

        <div style='position:absolute;top:133px;left:478px'> # </div> 
        <div style='position:absolute;top:133px;left:685px'> # </div>
        <div style='position:absolute;top:299px;left:270px'> # </div>
        <div style='position:absolute;top:299px;left:478px'> # </div>
        <div style='position:absolute;top:299px;left:685px'> # </div>        
        <div style='position:absolute;top:469px;left:270px'> # </div>
        <div style='position:absolute;top:469px;left:478px'> # </div>

        <img style='height:14cm' src='img/FriendsPlus/Page68/E1/1.jpg'/>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-68-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 120,
    stylesTextInput: {
      paddingLeft: 0,
      margin: 0,
      background: "none",
      borderColor: "gray",
    },
    maxLength: 40,
    // checkUppercase: false,
    exerciseKey: "img/FriendsPlus/Page68/Key/E2answerKey.png",
    textAlign: "left",
    titleQuestion: [
      {
        num: "2",
        title: "Look and write.",
        color: "#203c8f",
        left: 65,
      },
    ],
    noInputMargin: true,
    questions: [
      {
        title: `
        <img style='width: 25cm' src='img/FriendsPlus/Page68/E2/1.jpg'/>
        <span style='width: 25cm; display: block'>Today, four children went to see the school nurse because they were feeling ill. 
          Jimmy had a <span style='text-wrap: nowrap'><sup>1</sup> <u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspheadache&nbsp&nbsp</u></span> and a <span style='text-wrap: nowrap'><sup>2</sup> <u style='color:gray; text-underline-offset: 5px'>&nbsps</u>#</span> <span style='text-wrap: nowrap'><u style='color:gray; text-underline-offset: 5px'>&nbspt</u>#</span>. Sarah had a 
          <span style='text-wrap: nowrap'><sup>3</sup> <u style='color:gray; text-underline-offset: 5px'>&nbspt</u>#</span> . Becky had a bad <span style='text-wrap: nowrap'><sup>4</sup> <u style='color:gray; text-underline-offset: 5px'>&nbspc</u>#</span> and a <span style='text-wrap: nowrap'><sup>5</sup> <u style='color:gray; text-underline-offset: 5px'>&nbspc</u>#</span> . Aran had 
          a <span style='text-wrap: nowrap'><sup>6</sup> <u style='color:gray; text-underline-offset: 5px'>&nbsps</u><input width='170px'/></span> and an <span style='text-wrap: nowrap'><sup>7</sup> <u style='color:gray; text-underline-offset: 5px'>&nbspe</u>#</span> . The nurse gave the children 
          some water. She called their parents so they could go home.
        </span>
          `,
        answer: [
          "ore",
          "hroat",
          "oothache",
          "old",
          "ough",
          "tomachache",
          "arache",
        ],
      },
    ],
  },
};

export default json;
