import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P45-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page45/Key/E1answerKey.png",
    inputSize: 160,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // height: 15,
      // padding: "10",
    },

    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='display: inline-block; margin-top: -6px'> Write. <hintbox id=0></hintbox></span>",
        color: "#2d408e",
        left: 80,
      },
    ],
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "cooked",
          "helped",
          "stayed",
          "<span class='strikediag'>was</span>",
          "were",
          "used",
          "was",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='width: 25cm; display: flex; gap: 20px; margin-top: 20px; align-items: center'>
            <img style='width:10cm; margin-left: 25px' src='img/FriendsPlus/Page45/E1/1.jpg'/>
            <span>
              There <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspwas&nbsp&nbsp</u> was only one room in a Mayan home. 
              Everyone <sup>2</sup># this room for eating 
              and sleeping. There <sup>3</sup># no chairs 
              or tables. There <sup>4</sup># one door.
              Girls <sup>5</sup># at home and helped 
              their mothers. They didn’t go to school. They 
              <sup>6</sup># the food and cleaned the 
              home. Boys <sup>7</sup># their fathers on 
              the land.
            </span>
          </div>

         `,
        answer: ["used", "were", "was", "stayed", "cooked", "helped"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P45-E2",
    audio: "",
    video: "",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: 200,
      padding: 10,
      resize: "none",
      background: "none",
      // border: "1px solid",
      fontSize: 22,
      lineHeight: "1.3em",
    },
    // maxLength: 119,
    // textAlign: "center",
    inputSize: 320,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233);padding: 10px; position: relative'>
            <img src='img/FriendsPlus/Page45/E2/1.jpg' style='width: 100%'>
            <div style='position: absolute; top: 14px; left: 73px;'>
              <textarea placeholder='...' id=0 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 14px; left: 668px;'>
              <textarea placeholder='...' id=1 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 164px; left: 19px;'>
              <textarea placeholder='...' id=2 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 164px; left: 721px;'>
              <textarea placeholder='...' id=3 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 249px; left: 362px;'>
              <textarea placeholder='...' id=4 rows=3></textarea>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P45-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write about your last vacation. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(247, 154, 108); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=3 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
