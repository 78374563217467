import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P79-E1",
    exerciseKey: "img/FriendsPlus/Page79/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 80,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "<span>Read and write.  <hintbox id=0></hintbox></span>",
        color: "#203c8f",
        left: 55,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#253f8e",
      color: "#57585a",
      marginLeft: "10px",
      styleElementHintBox: {
        margin: "0px 15px",
        fontWeight: "normal",
      },
    },
    hintBox: [
      {
        src: [
          "build",
          "<span class='strikediag'>crowded</span>",
          "playgrounds",
          "robots",
          "spaceships",
          "travel",
        ],
        width: "12cm",
        inline: true,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; border: 1px solid; padding: 15px 20px; display: flex; flex-direction: column; gap: 10px; position: relative; box-shadow: 5px 5px 10px gray'>
          <img src='img/FriendsPlus/Page79/E1/1.png' style='width: 270px; position: absolute; top: -120px; right: -30px'>
          <span style='font-weight: bold; font-size: 27px'>What will life be like in 200 years?</span>
          <span>In 200 years, there will be lots more people, so it will be too<br><span style='text-wrap: nowrap'><sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbsp&nbsp&nbspcrowded&nbsp&nbsp&nbsp</u></span> crowded on Earth. I think we will have to <br><span style='text-wrap: nowrap'><sup>2</sup>#</span>new cities in space. People will live on space stations. There will be schools on the space stations. But there won’t be any <span style='text-wrap: nowrap'><sup>3</sup><input width='200px'/></span> . Maybe children will have <span style='text-wrap: nowrap'><sup>4</sup>#</span> to help them with their homework. I think we will all <span style='text-wrap: nowrap'><sup>5</sup>#</span> in big <span style='text-wrap: nowrap'><sup>6</sup>#</span> . They will be like trains for everybody to use.</span>
        </div>
        `,
        answer: ["build", "playgrounds", "robots", "travel", "spaceships"],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P73-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page73/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 23,
      width: 700,
    },
    textareaStyle: {
      // marginLeft: 7,
      background: "none",
      width: "100%",
      resize: "none",
      fontSize: 23,
      lineHeight: "2em",
      padding: 0,
      // border: "1px solid rgb(168, 168, 168)",
    },
    maxLength: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 55,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 27cm; border: 1px solid rgb(220, 221, 223); font-size: 22px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 20px'>
          <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 0 10px; text-align: center; position: relative'>
              <b style='color: rgb(0,136,202); font-size: 27px; margin-bottom: -10px'>What will life be like when we are 50?</b>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>number of people</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>oceans</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>weather</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>cities</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>transportation</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>schools</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>space</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [
          "Grandma, wolf, Red Riding Hood",
          "Red Riding Hood went to visit her Grandma.",
          "Red Riding Hood and the wolf met Grandma, who was scared of the wolf.",
          "Grandma made cookies and they lived happily ever after.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P73-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/Key/E3answerKey.png",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write an opinion paragraph. Use the notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(116, 119, 176); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id=0 rows=4></textarea>
          </div>
        `,
        answer: [],
        // fixedValue: ["My healthy habits"],
      },
    ],
  },
};

export default json;
