import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P64-E1",
    // audio: "Audios/07-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      fontSize: 25,
      height: "1.85em",
      width: 45.5,
      background: "none",
      border: "none",
      zIndex: 1,
      position: "relative",
    },
    titleQuestion: [
      {
        num: "1",
        title: "Complete the crossword.",
        color: "#2d408e",
        left: 50,
      },
    ],
    maxLength: 1,
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative; text-align:center'>
          <img src='img/FriendsPlus/Page64/E1/1.jpg' style='width: 25cm' />
          <div style='position: absolute; top: 34px; left: 541px; display:flex; flex-direction: column; gap: 0.5px'>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
          </div>
          <div style='position: absolute; top: 81px; left: 450px; display:flex; gap: 0.5px'>
            <div style='width: 45.5px;height: 1.85em'></div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px;height: 1.85em'></div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
          </div>
          <div style='position: absolute; top: 128px; left: 313px; display:flex; flex-direction: column; gap: 0.5px'>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
          </div>
          <div style='position: absolute; top: 175px; left: 267px; display:flex; gap: 0.5px'>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px;height: 1.85em'></div>
            <div style='width: 45.5px'>#</div>
            <div style='width: 45.5px'>#</div>
          </div>
        </div>
        `,
        answer: [
          "f",
          "o",
          "l",
          "l",
          "o",
          "w",
          "r",
          "s",
          "s",
          "c",
          "h",
          "a",
          "s",
          "e",
          "s",
          "o",
          "u",
        ],
        initialValue: [],
      },
    ],
  },
  2: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P64-E2",
    // audio: "Audios/07-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 30,
      height: 30,
      border: "1px solid #000",
      borderBottom: "1px solid #000",
      borderRadius: 5,
      padding: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text in the Student Book. Put the sentences in the correct order.",
        color: "#2d408e",
        left: 50,
      },
    ],
    maxLength: 1,
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 26cm; display: flex; gap: 20px; margin-bottom: 20px; align-items: center'>
          <img src='img/FriendsPlus/Page64/E2/1.jpg' style='width: 30%' />
          <div style='display: flex; gap: 5px; flex-direction: column'>
            <span><b>a. </b>The old woman wanted to eat the Gingerbread Man. #</span>
            <span><b>b. </b>The Gingerbread Man ran away fast. #</span>
            <span><b>c. </b>The old woman followed him quickly. #</span>
            <span><b>d. </b>An old woman made a Gingerbread Man. <div style="height: 30px; width: 30px; font-size: 24px; font-weight: 400; text-align: center; border: 1px solid rgb(0, 0, 0); border-radius: 5px; padding: 0 0 5px; display: inline-block; color: gray; line-height: 1.2em">1</div></span>
          </div>
        </div>
        <div style='width: 26cm; display: flex; gap: 20px; margin-bottom: 20px; align-items: center'>
          <img src='img/FriendsPlus/Page64/E2/2.jpg' style='width: 30%' />
          <div style='display: flex; gap: 5px; flex-direction: column'>
            <span><b>e. </b>He wanted to cross the river but he couldn’t<br> swim well. #</span>
            <span><b>f. </b>The old woman chased him to a river. #</span>
          </div>
        </div>
        <div style='width: 26cm; display: flex; gap: 20px; margin-bottom: 20px; align-items: center'>
          <img src='img/FriendsPlus/Page64/E2/3.jpg' style='width: 30%' />
          <div style='display: flex; gap: 5px; flex-direction: column'>
            <span><b>g. </b>The Gingerbread Man sat politely on the fox’s nose. #</span>
            <span><b>h. </b>The fox ate the Gingerbread Man. #</span>
            <span><b>i. </b>The Gingerbread Man saw a fox. #</span>
          </div>
        </div>
        `,
        answer: ["2", "3", "4", "6", "5", "8", "9", "7"],
        initialValue: [],
      },
    ],
  },
};

export default json;
