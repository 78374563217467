import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P91-E1",
    // audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page91/Key/E1answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Circle the correct word.",
        color: "#203c8f",
        left: -35,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: [
          "were / was",
          "was / were",
          "wasn't / weren't",
          "wasn't / weren't",
          "was / were",
          "was / were",
          "was / were",
          "wasn't / weren't",
        ],
        answers: ["0-0", "1-0", "2-0", "3-2", "4-0", "5-0", "6-2", "7-0"],
        initialValue: ["0-0"],
      },
      Layout: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px; position: relative'>
          <span><b>1. </b>There <input id=0 type='Circle'/> lots of cafés.</span>
          <span><b>2. </b>There <input id=1 type='Circle'/> a train.</span>
          <span><b>3. </b>There <input id=2 type='Circle'/> a trolley.</span>
          <span><b>4. </b>There <input id=3 type='Circle'/> any buses.</span>
          <span><b>5. </b>There <input id=4 type='Circle'/> a playground.</span>
          <span><b>6. </b>There <input id=5 type='Circle'/> a park. </span>
          <span><b>7. </b>There <input id=6 type='Circle'/> lots of trees.</span>
          <span><b>8. </b>There <input id=7 type='Circle'/> a museum.</span>
        </div>
      `,
    },
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P90-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page91/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Read and write <i>was, were, wasn't</i>, or <i>weren't</i>.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 120,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap: 7px'>
          <span><b>1. </b>There <u style='color: gray; text-underline-offset: 5px'>&nbspweren't&nbsp</u> any electric bikes 50 years ago.</span>
          <span><b>2. </b>There # a delicious birthday cake at the party last Sunday.</span>
          <span><b>3. </b>There # a bus, but there was a train.</span>
          <span><b>4. </b>There # twenty children at the park.</span>
          <span><b>5. </b>There # any scooters 100 years ago.</span>
        </div>
        `,
        answer: ["was", "wasn't", "were", "weren't"],
      },
    ],
  },
};

export default json;
