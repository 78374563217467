import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "R3",
    id: "WB5-2024-R3-P66-E1",
    exerciseKey: "img/FriendsPlus/Page66/Key/E1answerKey.png",
    audio: "Audios/28-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write yes or no. <span style='color: white'><headphone name='&nbsp 28' typeimg=sound src='Audios/28-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b>Blue lake is longer than Dove lake. <u style='color:gray; text-underline-offset: 5px'>&nbspNo&nbsp</u></span>
            <span><b>2. </b>Dove lake is deeper than Blue lake. #</span>
            <span><b>3. </b>Warning mountain is higher than Ossa mountain. #</span>
            <span><b>4. </b>Jenolan cave is longer than Son Doong cave. #</span>
          </div>
        `,
        answer: ["No", "No", "Yes"],
      },
    ],
  },
  2: {
    unit: "R3",
    id: "WB5-2024-R3-P66-E2",
    exerciseKey: "img/FriendsPlus/Page66/Key/E2answerKey.png",
    // audio: "Audios/28-audio.mp3",
    video: "",
    inputSize: 650,
    maxLength: 100,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Read. Write short answers.",
        color: "#203c8f",
        left: 50,
      },
    ],
    component: T6,
    stylesTextInput: {
      fontSize: 23,
    },
    // character: "/",
    questions: [
      {
        title: `
        <div style='width: 28cm; box-shadow: 3px 3px 0 rgb(232, 225, 223); border-radius: 8px; overflow: hidden'>
          <div style='width: 100%; background: rgb(45, 52, 91); color: white; text-align: center; padding: 10px'>
            <b style='font-size: 50px'>Welcome to the zoo</b>
            <div style='font-size: 25px'>Please read this visitor information.</div>
          </div>
          <div style='width: 100%; height: 40px; background: rgb(0, 173, 149)'>
          </div>
          <div style='width: 100%; display: flex; gap: 30px; padding: 20px; font-size: 23px'>
            <ul style='display: flex; flex-direction: column; gap: 10px; width: 55%'>
              <li>We have many amazing animals here. </li>
              <li>You mustn't shout loudly at them. It is scary for them.</li>
              <li>You mustn't give food to the animals, because they may eat it fast. It isn't healthy for them.</li>
            </ul>
            <ul style='display: flex; flex-direction: column; gap: 10px; width: 40% '>
              <li>You must put your litter neatly in a garbage can at the exit. </li>
              <li>We love animals and we must work together to make them safe and happy.</li>
            </ul>
          </div>
          <div style='width: 100%; height: 40px; background: rgb(0, 173, 149); padding: 0 60px; color: white; font-size: 23px'>
            Thank you!
          </div>
        </div>
        </div>
          <div style='width: 28cm; display: flex; flex-direction: column; gap: 10px; margin-top: 20px; font-size: 23px'>
            <span><b>1. </b>Who is the information for? <u style='color:gray; text-underline-offset: 5px'>&nbspZoo visitors.&nbsp</u></span>
            <span><b>2. </b>Why mustn't you shout loudly? <input width='661px'/></span>
            <span><b>3. </b>Why mustn't you give food to the animals? <input width='536px'/></span>
            <span><b>4. </b>Where must you put your litter? #</span>
            <span><b>5. </b>What must zookeepers and visitors do? <input width='1023px'/></span>
          </div>
        `,
        answer: [
          "It scares the animals.|Because it is scary for the animals.",
          "It isn't healthy for them.|Because it isn't healthy for them.",
          "In a garbage can at the exit|We must put our litter neatly in a garbage can at the exit.",
          "Work together|Work together to keep them safe and happy|They must work together to make them safe and happy.",
        ],
      },
    ],
  },
};

export default json;
