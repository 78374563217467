import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P20-E1",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/Key/E1answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Circle.",
        color: "#2d408e",
        left: 15,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "soda / ice_tea",
          "mushroom / meat",
          "lemonade / melon",
          "onion / melon",
          "onion / meat",
          "lemonade / ice_tea",
          "cucumber / lemonade",
          "onion / mushrooms",
        ],
        answers: ["0-0", "1-2", "2-0", "3-2", "4-0", "5-2", "6-0", "7-2"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 10px 20px; margin-top: 10px'>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/1.jpg'/>
          <span><input id=0 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/2.jpg'/>
          <span><input id=1 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/3.jpg'/>
          <span><input id=2 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/4.jpg'/>
          <span><input id=3 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/5.jpg'/>
          <span><input id=4 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/6.jpg'/>
          <span><input id=5 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/7.jpg'/>
          <span><input id=6 type='Circle'/></span>
        </div>
        <div style='display: flex; flex-direction: column; gap:10px; align-items: center'>
          <img style='height: 5cm' src='img/FriendsPlus/Page20/E1/8.jpg'/>
          <span><input id=7 type='Circle'/></span>
        </div>

      </div>
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P20-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/Key/E2answerKey.png",
    stylesTextInput: {
      width: 100,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Look and read. Write yes or no.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; gap: 30px; justify-content: space-between'>
          <div style='display: grid; grid-template-columns: 3fr 1fr; grid-gap: 10px'>
            <span><b>1. </b>There’s a cucumber.</span><span><u style='color:gray; text-underline-offset: 5px'>&nbspyes&nbsp</u></span>
            <span><b>2. </b> There’s some soda.</span><span>#</span>
            <span><b>3. </b>There’s some meat.</span><span>#</span>
            <span><b>4. </b>There’s an onion.</span><span>#</span>
            <span><b>5. </b>There’s a melon.</span><span>#</span>
            <span><b>6. </b>There are some mushrooms.</span><span>#</span>
            <span><b>7. </b>There’s an ice tea.</span><span>#</span>
          </div>
          <img style='height: 8cm' src='img/FriendsPlus/Page20/E2/1.jpg'/>
        </div>
        
        `,
        answer: ["no", "yes", "yes", "no", "yes", "no"],
      },
    ],
  },
};

export default json;
