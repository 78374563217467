import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P96-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page96/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Write sentences using going to.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 220,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      // gap: 15,
      paddingInline: 0,
      // marginLeft: 5,
      border: "1px solid #00aeef",
      marginRadius: 5,
      padding: "5px 20px",
      // color: "#57585a",
      styleElementHintBox: {
        // background: "#c6eafa",
        // padding: "5px 10px",
        // borderRadius: "5px",
        // margin: 0,
      },
    },
    // checkUppercase: true,
    hintBox: [
      {
        src: [
          "eat",
          "<span class='strikediag'>play</span>",
          "ride",
          "visit",
          "watch",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      paddingLeft: 0,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top: 10px; width: 27cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>I<u style='color: gray; text-underline-offset: 5px'>'m going to play&nbsp</u> soccer on the weekend.</span>
          <span><b>2. </b>He # a museum on Saturday.</span>
          <span><b>3. </b>They # a DVD.</span>
          <span><b>4. </b>I # a sandwich for lunch.</span>
          <span><b>5. </b>She # her bike in the morning.</span>
        </div>
        `,
        answer: [
          "'s going to visit|is going to visit",
          "'re going to watch|are going to watch",
          "'m going to eat|am going to eat",
          "'s going to ride|is going to ride",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P96-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page96/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Write questions using going to and short answers.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 300,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   // gap: 15,
    //   paddingInline: 0,
    //   // marginLeft: 5,
    //   border: "1px solid #00aeef",
    //   marginRadius: 5,
    //   padding: "5px 20px",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     // background: "#c6eafa",
    //     // padding: "5px 10px",
    //     // borderRadius: "5px",
    //     // margin: 0,
    //   },
    // },
    // // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: [
    //       "eat",
    //       "<span class='strikediag'>play</span>",
    //       "ride",
    //       "visit",
    //       "watch",
    //     ],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      paddingLeft: 0,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-top: 10px; width: 27cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b><u style='color: gray; text-underline-offset: 5px'>&nbsp&nbspIs she going to buy&nbsp</u> (she / buy) a smartphone? <b>(✓)</b> <u style='color: gray; text-underline-offset: 5px'>&nbsp&nbspYes, she is.&nbsp</u></span>
          <span><b>2. </b># (you / watch) a movie? <b>(✗)</b> # </span>
          <span><b>3. </b># (they / swim) in the sea? <b>(✓)</b> # </span>
          <span><b>4. </b># (he / play) video games? <b>(✗)</b> # </span>
          <span><b>5. </b># (you / go) to the beach? <b>(✗)</b> # </span>
        </div>
        `,
        answer: [
          "Are you going to watch",
          "No, I'm not.",
          "Are they going to swim",
          "Yes, they are.",
          "Is he going to play",
          "No, he isn't.",
          "Are you going to go",
          "No, I'm not.",
        ],
      },
    ],
  },
};

export default json;
