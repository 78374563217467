import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P49-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page49/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the table and complete the sentences.",
        color: "#203c8f",
        left: 60,
      },
    ],
    stylesTextInput: { width: 440 },
    // textAlign:'center',
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 25cm' src='img/FriendsPlus/Page49/E1/1.jpg'/>
          <b style='font-size:26px'>Class 5A School trip</b>
          <div style='width: 25cm; display: grid; grid-template-columns: 1fr 5fr; grid-gap: 5px 10px'>
            <span><b>1.</b>&ensp;(go <b>✓</b>)</span><span>Class 5A <u style='color:gray; text-underline-offset: 5px'>&nbspwent to a museum.&nbsp</u></span>
            <span><b>2.</b>&ensp;(buy <b>✗</b>)</span><span>They didn’t buy <input width='263px'/></span>
            <span><b>3.</b>&ensp;(see <b>✓</b>)</span><span>#</span>
            <span><b>4.</b>&ensp;(make <b>✗</b>)</span><span>#</span>
          </div>
          <b style='font-size:26px'>Class 5B School trip</b>
          <div style='width: 25cm; display: grid; grid-template-columns: 1fr 5fr; grid-gap: 5px 10px'>
            <span><b>5.</b>&ensp;(go <b>✗</b>)</span><span>#</span>
            <span><b>6.</b>&ensp;(see <b>✗</b>)</span><span>#</span>
            <span><b>7.</b>&ensp;(make <b>✓</b>)</span><span>#</span>
            <span><b>8.</b>&ensp;(buy <b>✓</b>)</span><span>#</span>
          </div>
        </div>
          `,
        answer: [
          "posters ",
          "They saw old bicycles.",
          "They didn't make models.",
          "Class 5B didn't go to the theater.",
          "They didn't see dinosaur models.",
          "They made books about dinosaurs.",
          "They bought postcards.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P49-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page49/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 150,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "buy",
          "<span class='strikediag'>do</span>",
          "eat",
          "go",
          "not do",
          "think",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: "Complete the dialog with the correct form of the words.",
        color: "#203c8f",
        left: 85,
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex; margin-top: 20px'>
            <div style='color: rgb(238, 29, 35)'>
                Ben<br>
                Sally<br>
                Ben<br>
                Sally<br>
                Ben<br>
                Sally<br>
                Ben<br>
                Sally
            </div>
            <div style='margin-left:30px'>
                <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspDid&nbsp</u> you go to the museum yesterday, Sally?<br>
                Yes, I did. I <sup>2</sup># with my friends.<br>
                Did you buy a model?<br>
                Yes, I did. And Lea <sup>3</sup># a postcard.<br>
                Did you eat your sandwiches inside?<br>
                No, we <sup>4</sup># . We <sup>5</sup># them outside.<br>
                Did you think it was fun?<br>
                Yes, I did, but my friends <sup>6</sup># it was scary!
            </div>
        </div>
        
        `,
        answer: ["went", "bought", "didn't", "ate", "thought"],
      },
    ],
  },
};

export default json;
