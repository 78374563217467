import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    id: "WB5-2024-U5-P34-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page34/Key/E1answerKey.png",
    stylesTextInput: {
      width: 280,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Order the letters. Write the countries.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='width: 26cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px'>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <img src='img/FriendsPlus/Page34/E1/1.jpg' style='width: 100%'/>
              <div style='padding: 0 140px 0 50px; text-align: center'><u style='color:gray; text-underline-offset: 5px'>&nbspAustralia&nbsp</u></div>
            </div>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <img src='img/FriendsPlus/Page34/E1/2.jpg' style='width: 100%'/>
              <div style='padding: 0 140px 0 50px; text-align: center'>#</div>
            </div>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <img src='img/FriendsPlus/Page34/E1/3.jpg' style='width: 100%'/>
              <div style='padding: 0 140px 0 50px; text-align: center'>#</div>
            </div>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <img src='img/FriendsPlus/Page34/E1/4.jpg' style='width: 100%'/>
              <div style='padding: 0 140px 0 50px; text-align: center'>#</div>
            </div>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <img src='img/FriendsPlus/Page34/E1/5.jpg' style='width: 100%'/>
              <div style='padding: 0 140px 0 50px; text-align: center'>#</div>
            </div>
            <div style='display: flex; flex-direction: column; gap: 10px'>
              <img src='img/FriendsPlus/Page34/E1/6.jpg' style='width: 100%'/>
              <div style='padding: 0 140px 0 50px; text-align: center'>#</div>
            </div>
          </div>
        `,
        answer: ["Thailand", "Germany", "France", "Viet Nam", "Cambodia"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "WB5-2024-U3-P34-E2",
    audio: "Audios/13-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page34/Key/E2answerKey.png",
    // stylesTextInput: {
    //   width: 300,
    // },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and match.  <span style='color: white'><headphone name='&nbsp 13' typeimg=sound src='Audios/13-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        // lineDirection: "row",
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: `
              <div style='display: flex; gap: 5px'><span>1. She lives in</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 0
          {
            children: `
              <div style='display: flex; gap: 5px'><span>2. He was in</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 1
          {
            children: `
              <div style='display: flex; gap: 5px'><span>3. Her favorite vacation was in</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 2
          {
            children: `
              <div style='display: flex; gap: 5px'><span>4. He lives in</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 3
          {
            children: `
              <div style='display: flex; gap: 5px'><span>5. She was in</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 4
          {
            children: `
              <div style='display: flex; gap: 5px'><span>6. They were in</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244); margin-top: 0.8em'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 5
          {
            children: `
              a. Singapore.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 6
          {
            children: `
              b. France.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 7
          {
            children: `
              c. Australia.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 8
          {
            children: `
              d. Germany.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 9
          {
            children: `
              e. the U.S.A.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 10
          {
            children: `
              f. Viet Nam.
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 11
        ],
        answers: ["0-11", "1-8", "2-6", "3-10", "4-7", "5-9"],
        initialValue: ["0-11"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; gap: 150px'>
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
          <input id='5' type='boxMatch' />
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px;'>
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <input id='9' type='boxMatch' />
          <input id='10' type='boxMatch' />
          <input id='11' type='boxMatch' />
        </div>
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P34-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page34/E2/1.jpg" }]],
  },
};

export default json;
