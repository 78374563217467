import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P95-E1",
    // audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page95/Key/E1answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Read and circle.",
        color: "#203c8f",
        left: -35,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: [
          "loudly / quietly",
          "hard / slowly",
          "neatly / quickly",
          "quietly / fast",
        ],
        answers: ["0-0", "1-0", "2-2", "3-0"],
        initialValue: ["0-0"],
      },
      Layout: `
        <div style='width: 25cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 20px'>
          <span><b>1. </b>You shouldn't <input id=0 type='Circle'/> in the library.</span>
          <span><b>3. </b>You must go <input id=2 type='Circle'/> to your class.</span>
          <span><b>2. </b>You should work <input id=1 type='Circle'/> at school.</span>
          <span><b>4. </b>Don't talk <input id=3 type='Circle'/>. I can't hear you.</span>
        </div>
      `,
    },
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P95-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page95/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 150,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      // marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 7px 20px'>
          <span><b>1. </b>You <u style='color: gray; text-underline-offset: 5px'>&nbspshouldn't&nbsp</u> go to bed late on a school night.</span>
          <span><b>3. </b>We # eat vegetables every day.</span>
          <span><b>2. </b>He # help his mom.</span>
          <span><b>4. </b>I # go swimming because I have a cold.</span>
        </div>
        `,
        answer: ["should", "should", "shouldn't"],
      },
    ],
  },
  3: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P95-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page95/Key/E3answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 200,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      // marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Schools <u style='color: gray; text-underline-offset: 5px'>&nbspwon't be&nbsp</u> (not be) the same in the future.</span>
          <span><b>2. </b>Astronauts # (visit) the moon soon.</span>
          <span><b>3. </b> # (we have) vacations in space in the future?</span>
          <span><b>4. </b>They # (not go) back to Germany.</span>
        </div>
        `,
        answer: ["will visit", "Will we have", "won't go"],
      },
    ],
  },
};
export default json;
