import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P54-E1",
    // audio: "Audios/18-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page54/Key/E1answerKey.png",
    inputSize: 200,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // height: 15,
      // padding: "10",
    },

    titleQuestion: [
      {
        num: "1",
        title: "Look and count. Look at the letters. Write the word.",
        color: "#2d408e",
        left: 55,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='width: 25cm; display: flex; flex-direction: column; margin-top: 20px; position: relative; z-index: 1'>
            <span><b>1. </b>There are eight <u style='color:gray; text-underline-offset: 5px'>&nbspmountains&nbsp</u>. <span style='padding: 2px 5px; text-shadow: 0 0 15px black; margin-left: 20px'>s i n m n u o t a</span></span>
            <span><b>2. </b>There are seven #. <span style='padding: 2px 5px; text-shadow: 0 0 15px black; margin-left: 20px'>l s a w t e a r l f</span></span>
            <span><b>3. </b>There is one ocean #. <span style='padding: 2px 5px; text-shadow: 0 0 15px black; margin-left: 20px'>d e p e</span></span>
            <span><b>4. </b>The ocean is #. <span style='padding: 2px 5px; text-shadow: 0 0 15px black; margin-left: 20px'>i w e d </span></span>
            <span><b>5. </b>There are two #. <span style='padding: 2px 5px; text-shadow: 0 0 15px black; margin-left: 20px'>i l s a d n s</span></span>
          </div>
          <img style='width:25cm; margin-top: -80px' src='img/FriendsPlus/Page54/E1/1.jpg'/>

         `,
        answer: ["waterfalls", "deep", "wide", "islands"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P54-E2",
    // audio: "Audios/18-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page54/Key/E2answerKey.png",
    inputSize: 350,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      // height: 15,
      // padding: "10",
    },

    titleQuestion: [
      {
        num: "2",
        title:
          "<span style='display: inline-block; margin-top: -30px'>Write <hintbox id=0></hintbox></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#ee1d23",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "wide",
          "<span class='strikediag'>waterfall</span>",
          "ocean",
          "high",
          "deep",
          "mountain",
          "lake",
          "high",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='width: 27cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 20px'>
            <div style='text-align: center'>
              <img style='width:100%' src='img/FriendsPlus/Page54/E2/1.jpg'/>
              <span>This is a <u style='color:gray; text-underline-offset: 5px'>&nbsphigh waterfall&nbsp</u>.</span>
            </div>
            <div style='text-align: center'>
              <img style='width:100%' src='img/FriendsPlus/Page54/E2/2.jpg'/>
              <span>This is a #.</span>
            </div>
            <div style='text-align: center'>
              <img style='width:100%' src='img/FriendsPlus/Page54/E2/3.jpg'/>
              <span>This is a #.</span>
            </div>
            <div style='text-align: center'>
              <img style='width:100%' src='img/FriendsPlus/Page54/E2/4.jpg'/>
              <span>This is a #.</span>
            </div>
          </div>

         `,
        answer: ["wide lake", "high mountain", "deep ocean"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P54-E3",
    audio: "Audios/Page54/audio-e3.mp3",
    video: "Videos/Unit 07.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='86' src='Audios/Page54/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/Page54/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/Page54/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/Page54/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/Page54/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/5.jpg",
          audioUrl: "Audios/Page54/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/6.jpg",
          audioUrl: "Audios/Page54/hinh6.mp3",
        },
      ],
    ],
  },
};

export default json;
