import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P11-E1",
    // audio: "Audios/Page10/E3/audio-e3.mp3",
    // video: "Videos/Unit 01.mp4",
    exerciseKey: "img/FriendsPlus/Page11/Key/E1answerKey.png",
    // textAlign: "center",
    stylesTextInput: {
      width: 50,
      paddingLeft: 0,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Complete the words.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='display:grid; grid-template-columns: repeat(3,minmax(200px, 1fr)); grid-gap: 10px; margin-top: 20px; width: 20cm'>
            <div style='display: flex; gap: 10px; align-items: center; flex-direction: column; width: 100%'>
              <img style='width: 100%' src='img/FriendsPlus/Page11/E1/1.jpg'/>
              <span>walk<u style='color:gray; text-underline-offset: 5px'>&nbsps&nbsp</u></span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center; flex-direction: column; width: 100%'>
              <img style='width: 100%' src='img/FriendsPlus/Page11/E1/2.jpg'/>
              <span>brush#</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center; flex-direction: column; width: 100%'>
              <img style='width: 100%' src='img/FriendsPlus/Page11/E1/3.jpg'/>
              <span>ride#</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center; flex-direction: column; width: 100%'>
              <img style='width: 100%' src='img/FriendsPlus/Page11/E1/4.jpg'/>
              <span>catch#</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center; flex-direction: column; width: 100%'>
              <img style='width: 100%' src='img/FriendsPlus/Page11/E1/5.jpg'/>
              <span>love#</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center; flex-direction: column; width: 100%'>
              <img style='width: 100%' src='img/FriendsPlus/Page11/E1/6.jpg'/>
              <span>sit#</span>
            </div>
          </div>
        `,
        answer: ["es", "s", "es", "s", "s"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P11-E2",
    audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page11/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 130,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Write the words. Then listen and check. <span style='color: white'><headphone name='&nbsp 03' typeimg=sound src='Audios/03-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#1eb26a",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>brushes</span>",
          "goes",
          "loves",
          "sits",
          "walks",
        ],
        width: "15cm",
      },
    ],
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='display: flex; gap: 30px; width: 20cm; align-items: center'>
            <img style='width: 20%' src='img/FriendsPlus/Page11/E2/1.jpg'/>
            <div style='display: flex; gap: 10px; flex-direction: column'>
              <span>Linh <sup>1</sup><span><u style='color:gray; text-underline-offset: 5px'>&nbspbrushes&nbsp</u></span> her teeth in the morning,</span>
              <span>And then she <sup>2</sup># to school.</span>
              <span>She <sup>3</sup># to class, she <sup>4</sup># on her chair.</span>
              <span>She <sup>5</sup># going to school!</span>
            </div>
          </div>
            
        `,
        answer: ["walks", "goes", "sits", "loves"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P11-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page11/Key/E3answerKey.png",
    // fontSize: 26,
    titleQuestion: [
      {
        num: "3",
        title: "Match the words with the same sound at the end. ",
        color: "#2d408e",
        left: -25,
      },
    ],
    maxLength: 1,
    component: Circle_Write,
    question: {
      DrawLines: {
        colorLineTo: "blue",
        widthLineto: 2,
        lineToNotCenter: true,
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              left: 31,
              top: 15,
              width: "80px",
              height: "59px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     left: 31,
          //     top: 15,
          //     width: "80px",
          //     height: "59px",
          //     // border: "1px solid #2eb6e1",
          //     // background: "white",
          //   },
          // }, // 1
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     left: 20,
          //     top: 15,
          //     width: "66px",
          //     height: "59px",
          //     // border: "1px solid #2eb6e1",
          //     // background: "white",
          //   },
          // }, // 2

          {
            boxMatchStyle: {
              position: "absolute",
              left: 47,
              top: 15,
              width: "111px",

              height: "59px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              left: 31,
              top: 15,
              width: "80px",
              height: "59px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              left: 44,
              top: 15,
              width: "106px",
              height: "59px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
        ],
        answers: ["0-2", "1-3"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative;width:20cm;height:5cm'>
        <div style='position: absolute; left: 7px; top: 2px; padding: 10px; border-radius: 20px; background: rgb(211, 239, 251)'><div style='position:relative; display: inline-block; width:0; height:1em'><input id='0' type='boxMatch' /></div>loves</div>
        <div id='lineto-1' style='position: absolute; left: 102px; top: 128px; padding: 10px; border-radius: 20px; background: rgb(254, 233, 213)'>walks</div>
        <div id='lineto-2' style='position: absolute; left: 263px; top: 61px; padding: 10px; border-radius: 20px; background: rgb(225, 238, 221)'>sits</div>
        <div style='position: absolute; left: 344px; top: 156px; padding: 10px; border-radius: 20px; background: rgb(251, 223, 235)'><div style='position:relative; display: inline-block; width:0; height:1em'><input id='1' type='boxMatch' /></div>brushes</div>
        <div style='position: absolute; left: 479px; top: 20px; padding: 10px; border-radius: 20px; background: rgb(247, 218, 206)'><div style='position:relative; display: inline-block; width:0; height:1em'><input id='2' type='boxMatch' /></div>rides</div>
        <div style='position: absolute; left: 643px; top: 75px; padding: 10px; border-radius: 20px; background: rgb(212, 212, 231)'><div style='position:relative; display: inline-block; width:0; height:1em'><input id='3' type='boxMatch' /></div>catches</div>
        <div style='position: absolute;left: 178px;to;top: 127px;height: 2px;width: 94px;transform: rotate(326deg);border-radius: 20px;background: blue;'></div>
      </div>
      `,
    },
  },
};

export default json;
