import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P62-E1",
    exerciseKey: "img/FriendsPlus/Page62/Key/E1answerKey.png",
    audio: "Audios/26-audio.mp3",
    video: "",
    // inputSize: 150,
    maxLength: 1,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write the number. <span style='color: white'><headphone name='&nbsp 26' typeimg=sound src='Audios/26-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#ee1d23",
    //   color: "#57585a",
    //   styleElementHintBox: {
    //     margin: "0px 20px",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "<span class='strikediag'>careful</span>",
    //       "good",
    //       "quiet",
    //       "polite",
    //     ],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    component: T6,
    stylesTextInput: {
      border: "none",
      background: "none",
      width: 30,
      height: 30,
      padding: 0,
    },
    questions: [
      {
        InputRong: true,
        title: `
        <div style='display: grid; grid-template-columns: repeat(6,1fr); grid-gap: 10px; width: 25cm'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page62/E1/1.jpg' style='width: 100%;' />
            <div style='position: absolute; bottom: 2px; right:10px'>#</div>
          </div>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page62/E1/2.jpg' style='width: 100%;' />
            <div style='position: absolute; bottom: 2px; right:8px'>#</div>
          </div>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page62/E1/3.jpg' style='width: 100%;' />
            <div style='position: absolute; bottom: 2px; right:10px'>#</div>
          </div>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page62/E1/4.jpg' style='width: 100%;' />
            <div style='position: absolute; bottom: 2px; right:8px'>#</div>
          </div>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page62/E1/5.jpg' style='width: 100%;' />
          </div>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page62/E1/6.jpg' style='width: 100%;' />
            <div style='position: absolute; bottom: 2px; right:8px'>#</div>
          </div>
        </div>
        `,
        answer: ["2", "4", "6", "3", "5"],
      },
    ],
  },
  2: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P62-E2",
    // audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page62/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Read and circle.",
        color: "#203c8f",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: [
          "carefully / fast",
          "slowly / neatly",
          "hard / loudly",
          "well / slowly",
        ],
        answers: ["0-0", "1-2", "2-2", "3-2"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='width: 20cm' src='img/FriendsPlus/Page62/E2/1.jpg'/>
        <div style='position: absolute; bottom: 30px; left: 40px; display: flex; flex-direction: column'>
          <span><b>1. </b>Ride your bikes <input id=0 type='Circle'/>.</span>
          <span><b>2. </b>Put your litter <input id=1 type='Circle'/> in<br>    the garbage can.</span>
          <span><b>3. </b>Don’t shout <input id=2 type='Circle'/>.</span>
          <span><b>4. </b>Walk <input id=3 type='Circle'/> on the path.</span>
        </div>
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P62-E3",
    exerciseKey: "img/FriendsPlus/Page62/Key/E3answerKey.png",
    // audio: "Audios/26-audio.mp3",
    video: "",
    inputSize: 350,
    maxLength: 100,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Write rules for the swimming pool.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#ee1d23",
    //   color: "#57585a",
    //   styleElementHintBox: {
    //     margin: "0px 20px",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "<span class='strikediag'>careful</span>",
    //       "good",
    //       "quiet",
    //       "polite",
    //     ],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    hideBtnFooter: true,
    component: T6,
    stylesTextInput: {
      // border: "none",
      background: "none",
    },
    questions: [
      {
        title: `
        <div style='position: relative'>
        <img style='width: 20cm' src='img/FriendsPlus/Page62/E3/1.jpg'/>
        <div style='position: absolute; bottom: 30px; left: 45px; display: flex; flex-direction: column; gap: 15px'>
          <span><b>1. </b>#</span>
          <span><b>2. </b>#</span>
          <span><b>3. </b>#</span>
          <span><b>4. </b>#</span>
        </div>
      </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
