import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P88-E1",
    // audio: "Audios/35-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page88/Key/E1answerKey.png",
    video: "",
    component: T6,
    // recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: "Complete the sentences.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 150,
    // styleHint: {
    //   border: "1px solid",
    //   justifyContent: "center",
    //   // gap: 0,
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   flexWrap: "nowrap",
    //   padding: "5px 30px",
    //   // marginTop: 20,
    //   marginLeft: -35,
    //   fontWeight: 400,
    //   styleElementHintBox: {
    //     // background: "#2bbbf1",
    //     // padding: "2px 5px",
    //     // borderRadius: "5px",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["bed", "dinner", "ice cream", "orange juice", "water"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>You have breakfast in the morning. You <u style='color:gray; text-underline-offset: 5px'>&nbspdon't have&nbsp</u> a snack in the morning.</span>
          <span><b>2. </b>She doesn't live in the U.S.A. She # in Australia. </span>
          <span><b>3. </b>We finish school at three o'clock. We # school at four o'clock.</span>
          <span><b>4. </b>I don't do my homework in the morning. I # my homework at night.</span>
          <span><b>5. </b>They don't walk to school. They # the bus.</span>
        </div>
        `,
        answer: ["lives", "don't finish", "do", "catch"],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P88-E2",
    // audio: "Audios/35-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page88/Key/E2answerKey.png",
    video: "",
    component: T6,
    // recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the question. Then write the short answer. (✗ = no, ✓ = yes)",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 100,
    // styleHint: {
    //   border: "1px solid",
    //   justifyContent: "center",
    //   // gap: 0,
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   flexWrap: "nowrap",
    //   padding: "5px 30px",
    //   // marginTop: 20,
    //   marginLeft: -35,
    //   fontWeight: 400,
    //   styleElementHintBox: {
    //     // background: "#2bbbf1",
    //     // padding: "2px 5px",
    //     // borderRadius: "5px",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["bed", "dinner", "ice cream", "orange juice", "water"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: grid; grid-template-columns: 2fr 1fr; grid-gap: 10px 30px'>
          <span><b>1. </b><u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspDoes&nbsp&nbsp</u> he get up at eight o'clock? <b>(✗)</b></span> <u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspNo, he doesn't.&nbsp&nbsp</u> 
          <span><b>2. </b> # we have a snack in the morning? <b>(✓)</b></span><input width='350px' textalign='start'/>
          <span><b>3. </b> # she live in a small house? <b>(✓)</b></span><input width='350px' textalign='start'/>
          <span><b>4. </b> # they walk to school? <b>(✗)</b></span><input width='350px' textalign='start'/>
        </div>
        `,
        answer: [
          "Do",
          "Yes, we do.",
          "Does",
          "Yes, she does.",
          "Do",
          "No, they don't",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E3",
    audio: "Audios/Page88/audio-e3.mp3",
    video: "Videos/Unit 12.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='143' src='Audios/Page88/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E3/1.jpg",
          audioUrl: "Audios/Page88/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/2.jpg",
          audioUrl: "Audios/Page88/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E3/3.jpg",
          audioUrl: "Audios/Page88/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/4.jpg",
          audioUrl: "Audios/Page88/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
