import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page53/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Read and write. Use the verbs in the simple past.",
        color: "#203c8f",
        left: 70,
      },
    ],
    stylesTextInput: { width: 174, background: "none" },
    textAlign: "center",
    questions: [
      {
        title: `
          <div style='position:relative'>
            <div style='position:absolute;top:155px;left:736px'>#</div>
            <div style='position:absolute;top:198px;left:672px'>#</div>
            <div style='position:absolute;top:285px;left:499px'>#</div>
            <div style='position:absolute;top:328px;left:262px'>#</div>
            <div style='position:absolute;top:372px;left:367px'>#</div>
            <img style='height:14cm' src='img/FriendsPlus/Page53/E1/1.jpg'/>
          </div>

          
          `,
        answer: ["saw", "knew", "ate", "bought", "thought"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page53/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      width: 600,
    },
    textareaStyle: {
      width: 600,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 25px'>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What’s the place?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>Where is it?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What is it like?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>When is the best time to visit?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What can you do there?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [""],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write a tourist leaflet. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(244, 132, 101); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=4 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
