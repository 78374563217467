import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "WB5-2024-U5-P39-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page39/Key/E1answerKey.png",
    stylesTextInput: {
      width: 50,
      paddingLeft: 0,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write B (beginning) or E (end).",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px'>
            <span><b>1. </b>How are you?<u style='color:gray; text-underline-offset: 5px'>&nbsp&nbsp&nbspB&nbsp&nbsp&nbsp</u>.</span>
            <span><b>2. </b>Write back soon! #.</span>
            <span><b>3. </b>Take care! #.</span>
            <span><b>4. </b>How was your weekend? #.</span>
        </div>
        `,
        answer: ["E", "E", "B"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "WB5-2024-U5-P39-E2",
    audio: "",
    video: "",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: 200,
      padding: 10,
      resize: "none",
      background: "none",
      // border: "1px solid",
      fontSize: 22,
      lineHeight: "1.3em",
    },
    // maxLength: 119,
    // textAlign: "center",
    inputSize: 320,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233);padding: 10px; position: relative'>
            <img src='img/FriendsPlus/Page39/E2/1.jpg' style='width: 100%'>
            <div style='position: absolute; top: 16px; left: 70px;'>
              <textarea placeholder='...' id=0 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 16px; left: 658px;'>
              <textarea placeholder='...' id=1 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 176px; left: 19px;'>
              <textarea placeholder='...' id=2 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 176px; left: 721px;'>
              <textarea placeholder='...' id=3 rows=3></textarea>
            </div>
            <div style='position: absolute; top: 294px; left: 326px;'>
              <textarea placeholder='...' id=4 width="300px" rows=3></textarea>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "WB5-2024-U5-P39-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write an email to a friend. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(247, 154, 108); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=7 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
